/* ------------------------------------------------------------------
 breadcrum
------------------------------------------------------------------ */
.c-breadcrum {
  padding-left: 15rem;
  margin-top: 90px;
  @include bp(sp) {
    display: none;
  }

  &__item {
    position: relative;
    // display: inline-block;
    display: inline-flex;
    font-size: 1.4rem;
    color: #707070;
    & + .c-breadcrum__item {
      margin-left: 0;
      &::before {
        display: inline-block;
        margin-right: 8px;
        vertical-align: middle;
        content: ">";
      }
    }

    a {
      position: relative;
      display: inline-block;
      font-size: 1.4rem;
      color: #c6c6c6;
      span {
        position: relative;
        &::before {
          position: absolute;
          bottom: -2px;
          left: 0;
          display: inline-block;
          width: 0;
          height: 1px;
          content: "";
          background-color: $black;
          transition: 0.2s ease-in-out;
        }
      }
      // &:hover{
      // 	span{
      // 		&::before{
      // 			width: 100%;
      // 		}
      // 	}
      // }
    }

    span {
      display: inline-block;
      font-weight: bold;
      vertical-align: middle;
    }
  }

  &__home {
    &::before {
      @include fontello("\e809");

      display: inline-block;
      margin-right: 7px;
      vertical-align: middle;
    }
  }

  &__inner {
    & > span {
      & > span {
        & > a {
          &::before {
            @include fontello("\e809");

            display: inline-block;
            margin-right: 7px;
          }
        }
      }
    }
    span {
      display: inline-block;
      font-size: 1.4rem;
      color: #707070;
    }
    a {
      position: relative;
      display: inline-block;
      margin-right: 13px;
      font-size: 1.4rem;
      color: $black;
      & + span {
        margin-left: 13px;
      }
      &::after {
        position: absolute;
        bottom: -2px;
        left: 0;
        display: inline-block;
        width: 0;
        height: 1px;
        content: "";
        background-color: $black;
        transition: 0.2s ease-in-out;
      }
      &:hover {
        &::after {
          width: 100%;
        }
      }
    }
  }
}
