/* ------------------------------------------------------------------
 icon
------------------------------------------------------------------ */
.c-iconPin {
  &::before {
    @include fontello("\e808");

    display: inline-block;
    margin-right: 5px;
    font-size: 1.2em;
  }
}

.c-iconCrown {
  &::before {
    @include fontello("\e802");

    display: inline-block;
    margin-right: 8px;
    font-size: 1em;
    color: $sub-color;
  }
}
