/* ------------------------------------------------------------------
 Bass
 ------------------------------------------------------------------ */

/*
 base-font-size : 10px;
 62.5% = 10px = 1em = 1rem
 (Default)
*/
html {
  font-size: 62.5%;
}

body {
  @include gothic;

  position: relative;
  width: 100%;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  padding: 0;
  margin: auto;
  overflow-x: hidden;
  font-size: 1.6rem;
  line-height: 2;
  color: $text-color;
  letter-spacing: 0.05em;
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
  @include bp(sp) {
    font-family: "fontello";
  }
}
* {
  box-sizing: border-box;
}

*,
*::after,
*::before {
  backface-visibility: hidden;
}
// heading base style
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  font-weight: normal;
  font-weight: 400;
  line-height: 1.4;
}
// txt base style

p {
  padding: 0;
  margin: 0;
  font-size: 1.6rem;
  font-weight: 400;
  letter-spacing: 0.04em;
}

dd,
dl,
dt,
li,
ol,
ul {
  padding: 0;
  margin: 0;
  vertical-align: baseline;
  background: transparent;
  border: 0;
  outline: 0;
}

li,
ul {
  list-style-type: none;
}

figure {
  margin: 0;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
}

a {
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  text-decoration: none;
}

button {
  padding: 0;
  font-size: inherit;
  color: inherit;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  appearance: none;
}

address {
  font-style: normal;
}

input:focus,
select:focus,
textarea:focus {
  outline: none;
  box-shadow: 0 0 1px 1px $main-color;
}

input:focus::placeholder,
textarea:focus::placeholder {
  color: transparent;
  transition: 0.1s;
}
.kinuta-marumaru {
  @include kinuta-marumaru;

  font-weight: bold;
}
