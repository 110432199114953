/* ------------------------------------------------------------------
 wysiwyg   共通
------------------------------------------------------------------ */
/* ------------------------
 wpコンテンツ
------------------------ */
.p-wysiwyg {
    @include bp(pc) {
        .l-section__inner--wysiwyg {
            max-width: $width-wysiwyg;
            width: $vw-width-wysiwyg;
        }//.l-section__inner
        .l-section__inner--wysiwyg-wide {
            max-width: $width-wysiwyg-wide;
            width: $vw-width-wysiwyg-wide;
        }//.l-section__inner
            
    }
    .c-pageheader {
        margin-bottom: 1.5rem;
        &__productShop {
            font-size: 1.2rem;
            border: 1px solid $text-color;
            padding: .5rem;
            display: inline-flex;
            line-height: 1;
            position: relative;
            bottom: -.75rem;
        }
        & + section {
            padding-top: 0!important;
        }
    }//pageheader
    .wp-block {
        &__intro {
            &__txt {
                margin-bottom: 5rem;
                @include bp(sp) {
                    margin-bottom: 1rem;
                }
            }
        }//__intro
        &__contents {
            &__title {
                font-size: 2.4rem;
                font-weight: bold;
                margin-bottom: 1rem;
                @include bp(sp) {
                    font-size: 2.0rem;
                }
                &__haifun {
                    display: flex;
                    align-items: center; 
                    margin-bottom: 2rem;
                    &:after,&:before {
                        content: '';
                        flex-grow: 1;/* 余白を分け与える */
                        height: 1px;
                        background: $text-color;
                    }
                    &:after {
                        margin-left: 1em;
                    }
                    &:before {
                        margin-right: 1em;
                    }
                }
                    
            }
            .productBtn {
                margin-top: 5rem;
                text-align: center;
                @include bp(sp) {
                    margin-top: 3rem;
                }
            }//productBtn
            .image-catch {
                line-height: 1.6;
                display: block;
                @include bp(pc) {
                    margin-top: -1rem;
                }
                
                @include bp(sp) {
                    margin-top: -1rem;
                    &:not(:last-child) {
                        margin-bottom: 2rem;
                    }
                }
            }
        }//__contents
    }//.wp-block

    .pickup__item {
        @include bp(sp) {
            &__bg_sp {
                background-color: $bg-color-wysiwyg-gray;
                padding-top: 4rem;
                padding-bottom: 4rem;
            }
        }
        &__wrap {
            @include bp(pc) {
                padding: 5rem 4rem;
                background-color: $bg-color-wysiwyg-gray;
            }
        }
        &__list {
            display: flex;
            margin-bottom: 2.5rem;
            padding-bottom: 2.5rem;
            border-bottom: 1px solid $text-color;
            @include bp(sp) {
                flex-wrap: wrap;
            }


        }//wrap
        &__name {
            line-height: 1.6;
            margin-bottom: 2.5rem;
            @include bp(sp) {
                margin-bottom: 1rem;
            }
            
            .en,.ja { display: block; }
            .en {
                font-size: 1.4rem;
                color: $text-color;
            }
            .ja {
                font-weight: bold;
            }
        }
        &__left {
            @include bp(pc) {
                padding-right: 6.8rem;
                flex: 1;
            }
            @include bp(sp) {
                margin-bottom: 1rem;
            }
            
        }
        &__right {
            @include bp(pc) {
                max-width: 357px;
                width: 100%;
            }
            @include bp(sp) {
                margin-bottom: 1rem;
            }
            
        }
        
        &__company {
            &__name {
                font-size: 2.0rem;
                font-weight: bold;
            }
            &__address {
                font-size: 1.4rem;
            }
            &__link {
                font-size: 1.4rem;
                display: inline-block;
                font-weight: bold;
            }                    
        }//__company
    }//.pickup__item

    .point__item {
        @include bp(sp) {
            &__bg_sp {
                background-color: $bg-color-wysiwyg-gray;
                padding-top: 4rem;
                padding-bottom: 4rem;
            }
        }//sp
        &__wrap {
            @include bp(pc) {
                padding: 5rem 4rem;
                background-color: $bg-color-wysiwyg-gray;
            }
        }//wrap
        &__list {
            display: flex;
            @include bp(sp) {
                flex-wrap: wrap;
            }//sp_layout
        }//list
        &__left {
            @include bp(pc) {
                margin-right: 1rem;
            }//pc_layout
            @include bp(sp) {
                margin-bottom: 1rem;
            }//sp_layout
        }
        &__right {
            @include bp(pc) {
                margin-left: 1rem;
            }
        }

        &__title {
            line-height: 1.6;
            margin-bottom: 2.5rem;
            font-weight: bold;
            margin-bottom: 2.5rem;
            padding-bottom: .5rem;
            border-bottom: 1px solid $text-color;
            @include bp(sp) {
                margin-bottom: 1rem;
            }//sp_layout
            .en,.txt {
                display: inline-block;
                @include bp(sp) {
                    display: block;
                }
            }
            .en {
                font-size: 2.0rem;
                @include bp(sp) {
                    font-size: 1.4rem;
                }//sp_layout
            }
            .txt {
                @include bp(pc) {
                    margin-left: 2rem;
                }//pc_layout
            }
        }//__title
        &__txt {
            @include bp(pc) {
                margin-bottom: 3rem;
            }
            @include bp(sp) {
                margin-bottom: 1rem;
            }
        }//__txt
        &__1pic {
            .point__item {
                &__name {
                    line-height: 1.6;
                    margin-bottom: 1rem;
                    padding-bottom: 1rem;
                    border-bottom: 1px solid #707070;
                    .ja,.en { display: block; }
                    .ja {
                        font-weight: bold;
                    }
                    .en {
                        font-size: 1.4rem;
                    }
                }//__name
                &__right {
                    @include bp(pc) {
                        max-width: 357px;
                    }
                    width: 100%;
                    margin-left: 0;
                }
                &__left {
                    @include bp(pc) {
                        padding-right: 6.8rem;
                        flex: 1;
                    }
                    margin-right: 0;
                }
                
            }//point__item
        }
        
    }//.point__item
    .column {
        &Area {
            @include bp(pc) {
                padding: 5rem 4rem;
            }
            &__bg {
                background-color: $bg-color-wysiwyg-gray;
                @include bp(sp) {
                    &_sp {
                        background-color: $bg-color-wysiwyg-gray;
                    }
                }
            }
        }//columnArea
        &__title {
            font-size: 2.0rem;
            font-weight: bold;
            margin-bottom: 1rem;
        }//column__title
            
    }//column

    //投稿ページで消えているパーツデザイン設定
    .wp-block__contents {
        ul {
            padding-left: 1.4em;
            li {
                list-style: disc;
            }
        }
        ol {
            padding-left: 1.4em;
            li {
                list-style-type: decimal;
            }
        }
    }

    .mokuji {
        &Area {
            @include bp(pc) {
                padding: 5rem 4rem;
            }
            &__bg {
                background-color: $bg-color-wysiwyg-gray;
                @include bp(sp) {
                    &_sp {
                        background-color: $bg-color-wysiwyg-gray;
                    }
                }
            }
        }//columnArea
        
        &__title {
            font-size: 2.0rem;
            font-weight: bold;
        }
        &__item {
            list-style: none!important;
            margin-left: -1.3em;
        }

    }

    

}//.p-wysiwyg

.p-newsSingle {
    .wp-block__contents {
        h1 {
            font-size: 2.4rem;
            font-weight: bold;
            margin-bottom: 1.5rem;
        }
        h2 {
            font-size: 2.0rem;
            font-weight: bold;
            margin-bottom: 1.5rem;
        }
    }//.wp-block__contents
}//.p-newsSingle



@include bp(pc) {

}//pc_layout
@include bp(sp) {

}//sp_layout