.p-about {
  &__article {
    @include bp(pc) {
      padding-right: 7.5rem;
      padding-left: 4rem;
    }
  }
  &__thoughts {
    margin-bottom: 4rem;
    &__item {
      display: flex;
      align-items: center;
      @include bp(sp) {
        flex-wrap: wrap;
        justify-content: center;
      }
      &:not(:last-child) {
        @include bp(pc) {
          margin-bottom: 9rem;
        }
        @include bp(sp) {
          margin-bottom: 5rem;
        }
      }
      // .p-about__thoughts__img img { width: 100%; }
      &01 .p-about__thoughts__img {
        img {
          width: 74px;
        }
      }
      &02 .p-about__thoughts__img {
        img {
          width: 70px;
        }
      }
      &03 .p-about__thoughts__img {
        img {
          width: 72px;
        }
      }
      &04 .p-about__thoughts__img {
        img {
          width: 157px;
        }
      }
    } //thoughts__item
    &__imgWrap {
      width: 100%;
      max-width: 157px;
      text-align: center;
      @include bp(pc) {
        margin-right: 4.5rem;
      }
      @include bp(sp) {
        margin-bottom: 2rem;
      }
    }
    &__title {
      display: flex;
      align-items: center;
      color: $main-color;
      @include shuei;
      @include bp(pc) {
        margin-bottom: 1rem;
        font-size: 2rem;
      }

      @include bp(sp) {
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        margin-bottom: 2rem;
        font-size: 1.8rem;
      }
    } //thoughts__title
    &__txtWrap {
      text-align: left;
      @include bp(pc) {
        flex: 1;
      }
    }

    &__txtTitle_no {
      @include bp(pc) {
        margin-right: 2rem;
      }

      @include bp(sp) {
        width: 100%;
        max-width: 58px;
        max-height: 34px;
        margin-bottom: 0.5rem;
      }
      img {
        @include bp(sp) {
          width: 48px;
          height: 29px;
        }
        @include bp(pc) {
          width: 67px;
          height: 39px;
        }
      }
    } //thoughts__txtTitle_no
    @include bp(sp) {
      &__txtTitle_txt {
        width: 100%;
        text-align: center;
      } //thoughts____txtTitle_txt
    }
  } //thoughts
} //about
