/* ------------------------------------------------------------------
 description
------------------------------------------------------------------ */

/* ----------------
 c-desc
 ---------------- */
.c-desc {
  position: relative;

  p {
    & + p {
      margin-top: 1em;
    }
  }
  &--center {
    @extend .c-desc;

    text-align: center;
  }
}
