/* ------------------------------------------------------------------
 category
------------------------------------------------------------------ */
.c-cat {
  @include bp(pc) {
    padding: 0 50px;
  }
  &__list {
    display: flex;
    flex-wrap: nowrap;
  }

  &__item {
    position: relative;
    & + .c-cat__item {
      margin-left: 20px;
    }

    a {
      font-weight: bold;
      color: $main-color;
      transition: 0.2s ease-in-out;
      @include bp(pc) {
        &:hover {
          color: $sub-color;
        }
      }
    }

    &::before {
      @include fontello("\e802");

      position: absolute;
      top: -5px;
      left: 50%;
      z-index: -1;
      display: inline-block;
      color: $sub-color;
      opacity: 0;
      transition: 0.2s ease-in-out;
      transform: translateX(-50%);
    }

    &.is-current {
      &::before {
        top: -20px;
        z-index: 1;
        opacity: 1;
      }
    }
  }
}
