.p-404 {
  @include bp(pc) {
    .l-section__inner--small {
      max-width: 742px;
    }
    .l-wrapper {
      // height: 100vh;
    }
    .c-pageheader {
      padding-top: 10rem;
    }
  }
  @media screen and (min-height: 920px) {
    .l-footer {
      // position: absolute;
      // bottom: 0;
    }
  }

  .c-pageheader {
    &__title {
      text-align: center;
      &__txt {
        color: $main-color;
        text-align: center;
        @include shuei;
        @include bp(sp) {
          margin-bottom: 2rem;
        }

        .big {
          font-size: 3.6rem;
          @include bp(sp) {
            font-size: 2.4rem;
          }
        }
        .small {
          padding-left: 2rem;
          font-size: 2.4rem;
          @include bp(sp) {
            padding-left: 1rem;
            font-size: 2rem;
          }
        }
      }
    }
  }
  .txtBox {
    .big {
      font-size: 2.4rem;
      font-weight: bold;
      @include bp(sp) {
        margin-bottom: 2rem;
        font-size: 1.8rem;
      }
    }
  }
} //404
