/* ----------------
 波の装飾
 ---------------- */
.wave__top {
  position: relative;
  &::before {
    position: absolute;
    top: -12px;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 12px;
    content: "";
    background: url(../img/common/wave_top.svg) repeat-x 0 0/96px 12px;
  }
}
.wave__btm {
  position: relative;
  &::after {
    position: absolute;
    bottom: -12px;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 12px;
    content: "";
    background: url(../img/common/wave_btm.svg) repeat-x 0 0/96px 12px;
  }
}
