.archive {
  &__wrap {
    @include bp(sp) {
      margin-top: 3rem;
    }
  }
  &__item {
    padding-bottom: 2rem;
    border-bottom: 1px solid $gray;
    @include bp(pc) {
      margin-bottom: 2rem;
    }
    @include bp(sp) {
      margin-bottom: 3.5rem;
    }

    a {
      display: block;
    }
    &Info {
      &Date {
        display: block;
        margin-bottom: 0.25rem;
        font-size: 1.4rem;
        // font-weight: bold;
        color: $archive-gray;
      }
      &Title {
        display: block;
        font-size: 1.6rem;
        font-weight: bold;
        @include bp(pc) {
          margin-bottom: 3.5rem;
        }
        @include bp(sp) {
          margin-bottom: 1.5rem;
        }
      }
      &Img {
        &Wrap {
          @include bp(pc) {
            display: flex;
          }
        } //archive__itemInfoImgWrap
      } //archive__itemInfoImg
    } //archive__itemInfo
    &Img {
      width: 100%;
      img {
        width: 100%;
      }
      @include bp(pc) {
        max-width: 270px;
        margin-right: 4rem;
      }
      @include bp(sp) {
        margin-bottom: 2rem;
      }
    } //archive__itemInfoImg

    &Hash {
      &__item {
        color: $archive-gray;
        &:not(:last-child) {
          margin-right: 0.5rem;
        }
      } //archive__itemHash
    } //__itemHash
  } //archive__item
} //archive
