/* ------------------------------------------------------------------
 footer
------------------------------------------------------------------ */
.l-footer {
  position: relative;
  width: 100%;
  background-color: $main-color;
  @include shuei;
  &__inner {
    position: relative;
    width: 100%;
    padding-top: 38px;
    padding-bottom: 5rem;
    color: $white;
    @include bp(pc) {
      display: flex;
      align-items: flex-end;
      // flex-wrap: wrap;
      justify-content: space-between;
      padding-top: 40px;
    }
    @include bp(sp) {
      padding-right: 2rem;
      padding-left: 2rem;
    }

    @media screen and (min-width: 768px) {
      padding-right: 180px;
      padding-left: 160px;
    }
  }
  &__Img {
    position: absolute;
    @include bp(pc) {
      top: -9.5rem;
    }
    @include bp(sp) {
      top: -7.2rem;
      left: 50%;
      transform: translateX(-50%);
    }
    img {
      @include bp(pc) {
        max-width: 380px;
      }
      @include bp(sp) {
        max-width: 256px;
      }
    }
  }

  &__btnBox {
    display: flex;
    @include bp(sp) {
      margin-bottom: 2rem;
    }

    &__btn {
      @include bp(sp) {
        width: 50%;
        &:first-child {
          padding-right: 0.75rem;
        }
        &:last-child {
          padding-left: 0.75rem;
        }
      }

      .c-btn {
        display: flex;
        justify-content: center;
        padding: 1rem 2.5rem;
        text-align: center;
        letter-spacing: 0.01em;
        background-color: $white;
        border: 1px solid $main-color;
        @include bp(sp) {
          padding: 1.5rem 2.5rem;
        }

        span {
          font-size: 1.2rem;
          line-height: 0.5em;
          color: $main-color;
          @include bp(sp) {
            font-size: 1.5rem;
          }
        }
        &::after {
          display: none;
        }
      }
      &.online {
        @include bp(pc) {
          display: none;
        }
      }
    } //l-footer__btnBox__btn
  } //l-footer__btnBox

  &__sns {
    @include bp(pc) {
      display: none;
    }
    @include bp(sp) {
      margin-bottom: 2rem;
      .c-sns {
        display: flex;
        align-items: center;
        &__item {
          padding-right: 0.75rem;
          padding-left: 0.75rem;
        }
        &__link::before {
          font-size: 2.5rem;
        }
        &__insta::before {
          font-size: 2.9rem;
        }
        &__tw::before {
          margin-top: 0.05rem;
          font-size: 3rem;
        }
      }
    }
  } //l-footer__sns
  &__right {
    display: flex;
    @include bp(pc) {
      flex-direction: row-reverse;
    }
    @include bp(sp) {
      margin-bottom: 5.5rem;
      font-size: 1.6rem;
    }
  }

  &__itemBox {
    @include bp(sp) {
      &__sp {
        &__right {
          width: 50%;
        }
        &__left {
          flex: 1;
          padding-left: 2rem;
        }
      }
    }
  } //l-footer__itemBox

  &__list {
    display: flex;
    @media screen and (-webkit-min-device-pixel-ratio: 0) {
      flex-direction: row-reverse;
    }
    @-moz-document url-prefix() {
      flex-direction: revert;
    }

    @include bp(pc) {
    }

    @include bp(sp) {
      flex-direction: column;
      flex-wrap: wrap;
    }
    .l-footer__item:not(:last-child) {
      @include bp(sp) {
        margin-bottom: 1.5rem;
      }
    }
  }

  &__item {
    transition: 0.2s ease-in-out;
    @include bp(pc) {
      padding-right: 2rem;
      padding-left: 2rem;
      letter-spacing: 0.2em;
      writing-mode: vertical-rl;
    }
    &:hover {
      opacity: 0.8;
    }

    &#footerNav__trigger {
      @include bp(pc) {
        padding-right: 0;
        margin-right: 2rem;
      }
    }
  }
  &__navLow {
    font-size: 1rem;
    // margin-right: 1rem;
    @include bp(pc) {
      font-size: 1.2rem;
      opacity: 0;
    }
    @include bp(sp) {
      margin-top: 1rem;
      font-size: 1.4rem;
    }

    &__item {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      @include bp(sp) {
        padding-left: 0;
        &:not(:last-child) {
          margin-bottom: 1rem;
        }
        &::before {
          content: "∟";
        }
        .kinuta-marumaru {
          @include kinuta-marumaru;

          font-weight: bold;
        }
      }
    }
    &.is-active {
      opacity: 1;
      transition: 0.5s ease-in-out;
      .l-footer__navLow_link:hover {
        opacity: 0.8;
        transition: 0.8s ease-in-out;
      }
    }
  }
  &__company {
    @include bp(pc) {
      margin-top: 0.9rem;
    }
  }

  .copyright {
    @include gothic;

    font-size: 1rem;
    // margin-top: 25px;
    @include bp(pc) {
      // margin-top: 18px;
    }
  }
}
