#more-button {
	line-height: 2;
	@include bp(pc) {
		margin-top: 3rem;
		&:hover {
			color: $main-color;
		}
	}
	&.icon-down-dir:before {
		display: inline-block;
		position: absolute;
		top: 50%;
		right: 19px;
		transform: translateY(-50%);
		z-index: 10;
		font-size: 1.8rem;
	}
	&:after {
		display: none;
	}
}
.infinite-scroll-request {
	margin-bottom: 2rem;
}
.infinite-scroll-last {
	margin-top: 3rem;
}
.scroller-status {
	margin-bottom: 3rem;
	display: none;
}

