/* ------------------------------------------------------------------
 Top
------------------------------------------------------------------ */

/* ------------------------
 Layout
------------------------ */
// header,nav { display: none; }
@include bp(pc) {
  .heroNewsWrap {
    position: relative;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 165px;
      height: 100%;
      content: "";
      background-color: #fff;
    }
  }
}

.top {
  .l-main {
  }

  .l-section_top {
    @include bp(pc) {
      &:not(:last-child),
      &:not(:first-child) {
        margin-bottom: 23rem;
      }
    }
    @include bp(sp) {
      margin-bottom: 6.5rem;
    }

    &.topMeguru,
    &.topOnline,
    &.topCreate {
      @include bp(wide) {
        max-width: 1480px;
        margin-right: auto;
        margin-left: auto;
      }
    }
  }
  @include bp(pc) {
    .l-main {
      .c-btn {
        padding: 15px 0 15px;
      }
    }
  }

  .c-sectionTitle {
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    @include shuei;
    @include bp(pc) {
      margin-bottom: 3rem;
      font-size: (30 / 1400) * 100 + vw;
      @supports (paint-order: fill) and (-webkit-marquee-speed: 0) {
        font-weight: 400;
      }
      @-moz-document url-prefix() {
        font-weight: 400;
      }
    }
    @include bp(sp) {
      margin-bottom: 1rem;
      font-size: (30 / 375) * 100 + vw;
    }

    &.color-main {
      color: $main-color;
    }
    &__en {
      @include shuei;

      font-size: 3.4rem;
      font-weight: bold;
      line-height: 1;
      letter-spacing: 2.64px;
      @include bp(pc) {
        font-size: 6rem;
      }
    }
    &__jp {
      font-size: 1.3rem;
      font-weight: bold;
      letter-spacing: 1.04px;
      @include bp(pc) {
        margin-top: 10px;
        font-size: 1.4rem;
        letter-spacing: 1px;
      }
    }
    &.vertical-rl {
      @include bp(pc) {
        // text-align: start;
        text-align: justify;
        writing-mode: vertical-rl;
      }
    }
  }

  .c-section__mainTxt {
    font-size: (15 / 1400) * 100 + vw;
    @include bp(pc) {
      line-height: 2.5;
    }

    @include bp(sp) {
      font-size: (16 / 375) * 100 + vw;
      text-align: left;
    }
  }

  .topMeguru,
  .topOnline,
  .topCreate {
    .c-sectionTitle {
      @include bp(sp) {
        font-size: (24 / 375) * 100 + vw;
      }
    }
  } //.topMeguru,.topOnline

  //top_common
  .topSection {
    &__imgTxtWrap {
      @include bp(pc) {
        display: flex;
      }
      &.end {
        @include bp(pc) {
          justify-content: flex-end;
        }
      }
    } //topSection__imgTxtWrap
    &__TxtWrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-top: 3rem;
      text-align: center;
      @include bp(pc) {
        width: 40%;
        padding-right: 6rem;
        padding-left: 6rem;
      }
      @include bp(sp) {
        max-width: 100%;
        padding: 0 $spGutter;
        margin-top: 3rem;
      }
    } //topSection__TxtWrap
    &__img {
      @include bp(pc) {
        width: 60%;
      }
      // max-width: 1000px;
    } //topSection__img
    &__mainImg {
      // max-width: 870px;
      &.left img {
        border-radius: 0 35px 35px 0;
        @include bp(sp) {
          border-radius: 0 25px 25px 0;
        }
      }
      &.right img {
        border-radius: 35px 0 0 35px;
        @include bp(sp) {
          border-radius: 25px 0 0 25px;
        }
      }
      @include bp(wide) {
        &.left img {
          border-radius: 35px;
          @include bp(sp) {
            border-radius: 25px;
          }
        }
        &.right img {
          border-radius: 35px;
          @include bp(sp) {
            border-radius: 25px;
          }
        }
      }

      img {
        object-fit: fill;
        width: 100%;
      }
    }

    &__btn {
      margin-top: auto;
      // padding-bottom: 5.6rem;
      @include bp(pc) {
        padding-top: 4rem;
        margin-bottom: 5.6rem;
      }
      @include bp(sp) {
        padding-top: 3.5rem;
      }
    }
  } //topSection

  .is-deco {
    &.rotate15 {
      transform: rotate(15deg);
    }
    &.rotate-15 {
      transform: rotate(-15deg);
    }
  }
  // .is-nomalstae {
  //     transform:rotate(0)!important;
  // }
  .is-nomalstae {
    &.rotate15 {
      transform: rotate(-15deg) !important;
    }
    &.rotate-15 {
      transform: rotate(15deg) !important;
    }
    &.bird__deco {
      transform: rotate(0) !important;
    }
  }

  &__img__deco {
    position: absolute;
    z-index: 2;
    // top: -5rem;
    // right: -$move_gion;
    // width: $move_gion;
    &__moveWrap {
      position: relative;
      width: 100vw;
      // overflow: hidden;
    } //top__img__deco__moveWrap
  } //top__img__deco

  &__yellowCon {
    padding-top: 7.5rem;
    padding-bottom: 10rem;
    @include bp(sp) {
      padding-bottom: 6rem;
    }
  }
  @include bp(sp) {
    .is-tap_sp {
      position: relative;
      &::after {
        position: absolute;
        right: 2.5rem;
        bottom: 0.9rem;
        z-index: 2;
        font-size: 1.8rem;
        color: #fff;
        content: "tap";
        @include shuei;
      }
      &::before {
        @include is-anim-poyopoyo;

        position: absolute;
        right: 1.5rem;
        bottom: 0;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 51px;
        height: 51px;
        content: "";
        background-color: $main-color;
        border-radius: 51px;
        box-shadow: 0 2px 8px 0 rgba($main-color, 0.5);
      }
    }
  }

  .hero {
    position: relative;
    overflow: hidden;
    @include bp(sp) {
      margin-top: 1.5rem;
    }
    &__img {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 1;
      transform: translateY(-50%) translateX(-50%);
      @include bp(sp) {
        width: 100%;
        max-width: 307px;
      }
    }
  }
  .slide {
    @include bp(pc) {
      width: 11448px;
      height: 708px;
      background: url(../img/top/main_img_pc@2x.jpg) repeat-x;
      background-size: contain;
    }
    @include bp(sp) {
      width: 5756px;
      height: 605px;
      background: url(../img/top/main_img_sp@2x.jpg) repeat-x;
      background-size: contain;
    }
  }

  .topNews {
    color: $white;
    background-color: $main-color;
    @include bp(pc) {
      margin-bottom: 0 !important;
      // margin-bottom: 9.5rem;
    }
    @include bp(sp) {
      margin-bottom: 0 !important;
      // margin-bottom: 6.0rem;
    }

    &__item {
      @include bp(pc) {
        padding-top: 2rem;
        padding-bottom: 2rem;
      }

      @include bp(sp) {
        padding-top: 1rem;
        padding-bottom: 1rem;
      }

      a {
        display: flex;
        align-items: center;
      }
      &Date {
        margin-right: 3.5rem;
        font-size: 1.1rem;
        font-weight: bold;
        @include bp(sp) {
          display: block;
        }
      }
      &Title {
        font-size: 1.3rem;
        font-weight: bold;
        @include bp(sp) {
          display: block;
        }
      }
      @include bp(pc) {
        &Info {
          display: flex;
        }
      }
    } //topNews__item
    &__listLink {
      margin-left: auto;
    } //topNews__listLink
    &__inner {
      display: flex;
      @include bp(sp) {
        align-items: center;
      }
    } //topNews__inner
    &__list {
      @include bp(pc) {
        flex: 1;
        padding-right: 3rem;
      }

      @include bp(sp) {
        width: 76%;
      }
    } //topNews__list

    &__listLink {
      font-size: 1.3rem;
      font-weight: bold;
      @include bp(pc) {
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 90px;
      }
      @include bp(sp) {
        width: 30%;
        margin-right: -2rem;
      }
    }
  } //topNews

  .topIntro {
    @include bp(pc) {
      margin-top: 9.5rem;
    }

    @include bp(sp) {
      margin-top: 6.5rem;
      .c-sectionTitle {
        text-align: center;
      }
      p {
        font-size: 1.4rem;
      }
    }
    &__txtBox {
      margin-bottom: 3rem;
    }
    &__img {
      &__pc__deco {
        &01 {
          left: -3rem;
          width: 66.8px;
        }
        &02 {
          bottom: -2rem;
          left: 0;
          width: 37.56px;
        }
        &03 {
          top: -11rem;
          left: -2rem;
          width: 35px;
        }
        &04 {
          width: 65px;
        }

        &05 {
          bottom: 4rem;
          left: 3.5rem;
          width: 30px;
        }
        &06 {
          bottom: -3rem;
          left: -1rem;
          width: 30px;
        }
        &07 {
          top: -14rem;
          left: -1.5rem;
          width: 37px;
        }

        &08 {
          bottom: -3rem;
          left: -1rem;
          width: 57px;
        }
        &09 {
          top: -8.5rem;
          left: 0.5rem;
          width: 47px;
        }
        &10 {
          bottom: -3rem;
          left: 1rem;
          width: 64px;
        }
      }
      &Wrap {
        display: flex;
        justify-content: center;
        @include bp(pc) {
          align-items: flex-end;
        }
        @media only screen and (min-width: 767px) and (max-width: 2429px) {
          &.left {
            margin-right: 4rem;
          }
          &.right {
            margin-left: 4rem;
          }
        }
        @media screen and (min-width: 2430px) {
          &.left {
            margin-right: 3.5rem;
          }
          &.right {
            margin-left: 3.5rem;
          }
        }

        @include bp(sp) {
          align-items: center;
          margin-bottom: 2rem;
        }

        @include bp(pc) {
          &__pclyout {
            display: flex;
            justify-content: center;
          }
        }
      } //topIntro__imgWrap
      &__deco {
        position: relative;
        img {
          width: 100%;
        }
        @include bp(sp) {
          &01 {
            bottom: -3rem;
          }
          &03 {
            top: 1rem;
          }
          &04 {
            bottom: -4rem;
          }
          &05 {
            top: -2rem;
          }
          &06 {
            bottom: -2rem;
            width: 24px;
          }
          &yama,
          &Hoko,
          &Fune,
          &Kasa {
            margin-right: 0.5rem;
            margin-left: 0.5rem;
          }
        }
      } //topIntro__img__deco
    } //topIntro__img
  } //topIntro

  .topMeguru {
    @include bp(sp) {
      .c-sectionTitle {
        text-align: left;
      }
    }

    .topSection__imgTxtWrap {
      @include bp(sp) {
        position: relative;
      }
    }
    .topMeguru__img {
      position: relative;
      @include bp(sp) {
        padding-right: $spGutter;
      }
      @include bp(pc) {
        &Wrap {
          position: relative;
          display: flex;
        }
      }
      &__deco {
        position: absolute;
        z-index: 1;
        &01 {
          top: -9rem;
          left: 8rem;
          max-width: 117px;

          @include bp(sp) {
            display: none;
          }
        }
        &02 {
          max-width: 32px;
          @include bp(pc) {
            bottom: 6rem;
            left: 2rem;
          }

          @include bp(sp) {
            top: -5rem;
            right: 4rem;
          }
        }
        &03 {
          max-width: 33px;
          @include bp(pc) {
            bottom: 2rem;
            left: 6rem;
          }

          @include bp(sp) {
            top: 5rem;
            right: 5rem;
          }
        }
        &04 {
          @include bp(sp) {
            top: 3rem;
            right: 9rem;
          }
        }
      }
    } //topMeguru__img
  } //topMeguru

  .topOnline {
    @include bp(pc) {
      padding-left: 150px;
    }
    @include bp(sp) {
      .topSection__imgTxtWrap {
        display: flex;
        flex-wrap: wrap;
      }
      .topOnline__img {
        order: 1;
      }
      .topOnline__txtWrap {
        order: 2;
      }
    } //@include bp(sp)
    @include bp(pc) {
      .topSection__TxtWrap {
        padding-left: 0;
      }
    }
    @include bp(sp) {
      .c-sectionTitle {
        text-align: right;
      }
    }

    .topOnline__img {
      position: relative;
      @include bp(sp) {
        padding-left: $spGutter;
      }

      &Wrap {
        position: relative;
        @include bp(pc) {
          display: flex;
        }
      }
      &__deco {
        position: absolute;
        z-index: 1;
        &01 {
          top: -8rem;
          left: -6rem;
          max-width: 73px;
          @include bp(sp) {
            top: -3rem;
            left: 3rem;
            max-width: 55px;
          }
        }
        &02 {
          top: 0;
          left: -2rem;
          max-width: 73px;
          @include bp(sp) {
            top: 3rem;
            left: 6rem;
            max-width: 55px;
          }
        }
        &03 {
          top: -2.5rem;
          left: -7.5rem;
          max-width: 48px;
          @include bp(sp) {
            top: 2rem;
            left: 2rem;
            max-width: 44px;
          }
        }
      }
    } //topOnline__img

    .c-sectionTitle {
      @include bp(sp) {
        margin-right: unset;
      }
    }
  } //topOnline

  .topPlay {
    .top__img__deco {
      top: -5rem;
    }

    .c-sectionTitle {
      margin-bottom: 1.5rem;
      &__txt {
        text-align: center;
      }
    }

    &__list {
      margin-right: auto;
      margin-left: auto;
      @include bp(pc) {
        display: flex;
        margin-top: 6rem;
      }
      @include bp(sp) {
        margin-top: 9rem;
      }
    }
    &__item {
      @include bp(pc) {
        width: 33.33333%;
        &:last-child {
          margin-left: 1.5rem;
        }
      }
      @include bp(sp) {
        &:not(:last-child) {
          margin-bottom: 8rem;
        }
      }

      a {
        position: relative;
        display: block;
        height: 100%;
      }
      &__NameBox {
        margin-top: 3rem;
        line-height: 1.2;
        color: $main-color;
        text-align: center;

        @include bp(sp) {
          margin-top: 2rem;
        }

        .name {
          display: block;
          font-size: 2.4rem;
          @include shuei;
          &__en {
            font-size: 1.6rem;
          }
        }
      } //__NameBox
    } //topPlay__item
    &__img {
      position: relative;
      @include bp(pc) {
        height: (356 / 1080) * 100 + vw;
        max-height: 356px;
      }
      .is-motif {
        margin-right: auto;
        margin-left: auto;
        text-align: center;
        @include bp(pc) {
          position: absolute;
          bottom: 0;
          @media all and (-ms-high-contrast: none) {
            left: 16%;
          }
        }

        &__wrap {
          @include bp(pc) {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
          }
        }
        &__hover__txt {
          position: relative;
          @include bp(pc) {
            &::before {
              position: absolute;
              top: -2rem;
              bottom: 0;
              left: -5rem;
              z-index: 2;
              display: none;
              width: 224px;
              height: 167px;
              content: "";
            }
          }
          @include bp(pc) {
            &.tenugui {
              &::before {
                background: url(../img/top/tenugui-hover_txt.svg) no-repeat;
              }
            }
            &.gosyuincho {
              &::before {
                background: url(../img/top/gosyuincho-hover_txt.svg) no-repeat;
              }
            }

            &.kaishi {
              &::before {
                top: -5rem;
                width: 246px;
                height: 206px;
                background: url(../img/top/kaishi-hover_txt.svg) no-repeat;
              }
            }
          } //@include bp(pc)
        } //&__hover__txt
        &.tenugui {
          &.is-motif__hover {
            position: relative;
          } //is-motif__hover
        } //tenugui
      } //is-motif
    } //topPlay__img

    .tenugui.main {
      img {
        width: 127px;
        height: 333px;
      }
    }
    .gosyuincho.main {
      img {
        width: 213px;
        height: 280px;
      }
    }
    .is-mouseover {
      position: absolute;
      bottom: 0;
      @include bp(pc) {
        @media all and (-ms-high-contrast: none) {
          left: 16%;
        }
      }
    }

    &__item__link {
      .is-nomal {
        display: block;
      }
      .is-mouseover {
        display: none;
      }
      @include bp(pc) {
        &:hover {
          .is-nomal {
            display: none;
          }
          .is-mouseover {
            position: absolute;
            bottom: 0;
            display: block;
          }
          .is-motif__hover__txt {
            &::before {
              display: block;
            }
          }
        } //&:hover
      }
    } //topPlay__item__link
    .is-motif__wrap {
      position: relative;
      .is-scroll-showItem {
        position: absolute;
        top: -6rem;
        z-index: 1;
        max-width: 200px;
      }
    }
  } //topPlay

  .topCreate {
    @include bp(sp) {
      .topSection__imgTxtWrap {
        position: relative;
      }
      .c-sectionTitle {
        text-align: left;
      }
    }
    .topCreate__img {
      position: relative;
      @include bp(sp) {
        padding-right: $spGutter;
      }
      @include bp(pc) {
        &Wrap {
          position: relative;
          display: flex;
        }
      }
      &__deco {
        position: absolute;
        z-index: 1;
        &01 {
          top: -13rem;
          left: 2rem;
          max-width: 270px;
          @include bp(sp) {
            display: none;
          }
        }
        &02 {
          max-width: 38px;
          @include bp(pc) {
            bottom: 8rem;
            left: 2rem;
          }
          @include bp(sp) {
            top: -2.5rem;
            right: 3.5rem;
          }
        }
        &03 {
          max-width: 54px;
          @include bp(pc) {
            bottom: 2rem;
            left: 4.5rem;
          }
          @include bp(sp) {
            top: 2rem;
            right: 4rem;
          }
        }
      }
    } //topCreate__img
  } //topCreate

  .topFound {
    .top__img__deco {
      top: -12rem;
    }
    .c-sectionTitle__txt {
      text-align: center;
      @include bp(sp) {
        margin-bottom: 4rem;
      }
    }
    &__list {
      margin-bottom: 2.3rem;
      @include bp(pc) {
        display: flex;
        margin-top: 6.5rem;
      }
    }
    &__item {
      position: relative;
      display: flex;
      justify-content: center;
      @include bp(pc) {
        width: 33.3333%;
        margin-right: 1.5rem;
        margin-left: 1.5rem;
      }
      @include bp(sp) {
        &:not(:last-child) {
          margin-bottom: 4rem;
        }
      }

      .is-nomal {
        display: block;
      }
      .is-mouseover {
        display: none;
      }
      @include bp(pc) {
        &:hover {
          cursor: pointer;
          .is-nomal {
            display: none;
          }
          .is-mouseover {
            display: block;
          }
        }
      }
      @include bp(sp) {
        .topFound__img {
          display: none;
        }
        .topFound__img.is-show {
          display: block;
        }
      }
    }
    &__img {
      position: relative;
      &NameBox {
        position: absolute;
        bottom: 4rem;
        left: 50%;
        z-index: 1;
        transform: translateX(-50%);
        @include bp(tab) {
          bottom: 2rem;
          font-size: 1.2rem;
        }

        .name,
        .txt {
          display: block;
          font-weight: bold;
          color: $main-color;
          text-align: center;
          letter-spacing: 0.075em;
          @include gothic;
        }
      }
      &.is-mouseover {
        .topFound__img {
          &NameBox {
            position: absolute;
            top: 44%;
            // top: 50%;
            left: 50%;
            z-index: 1;
            width: 100%;
            transform: translateY(-50%) translateX(-50%);
            .name,
            .txt {
              color: $white;
            }
            .name {
              margin-bottom: 2rem;
            }
          }
        } //topFound__img
      } //is-mouseover
    } //topFound__img
    // .is-motif {
    //     position: relative;
    //     &:before {
    //         content: '';
    //         position: absolute;
    //         z-index: 1;
    //         top: 50%;
    //         left: 50%;
    //         transform: translateY(-60%) translateX(-50%);
    //     }
    //     &.ochigo {
    //         &:before {
    //             content: '';
    //             background: url(../img/top/ochigo.svg) no-repeat;
    //             width: 88px;
    //             height: 170px;
    //         }
    //         &.is-motif__hover {
    //             &:before {
    //                 background: url(../img/top/ochigo_hover.svg) no-repeat;
    //             }
    //         }
    //     }//ochigo
    //     &.bird {
    //         &:before {
    //             content: '';
    //             background: url(../img/top/bird.svg) no-repeat;
    //             width: 195px;
    //             height: 145px;
    //         }
    //         &.is-motif__hover {
    //             &:before {
    //                 background: url(../img/top/bird_hover.svg) no-repeat;
    //             }
    //         }
    //     }//bird
    //     &.plant {
    //         &:before {
    //             content: '';
    //             background: url(../img/top/plant.svg) no-repeat;
    //             width: 85px;
    //             height: 142px;
    //         }
    //         &.is-motif__hover {
    //             &:before {
    //                 background: url(../img/top/plant_hover.svg) no-repeat;
    //             }
    //         }
    //     }//plant
    // }//is-motif

    .is-motif {
      img {
        max-width: 269px;
      }
    }
  } //topFound

  .topInsta {
    margin-bottom: 0 !important;
    overflow: hidden;
    .c-sectionTitle {
      font-size: 3.6rem;
    }
    &__img {
      &Wrap {
        position: relative;
      } //__imgWrap
      &__deco {
        position: absolute;
        &01 {
          @include bp(pc) {
            top: -5.2rem;
            left: 18rem;
          }
          @include bp(sp) {
            top: -2.2rem;
            right: 3rem;
            width: 60px;
          }
        }
        &02 {
          top: 44%;
          left: 18rem;
          @include bp(sp) {
            top: 23%;
            left: 15rem;
            width: 74px;
          }
        }
        &03 {
          top: 1rem;
          left: 33%;
          @include bp(sp) {
            top: 18rem;
            left: 2rem;
            width: 47px;
          }
        }
        &04 {
          @include bp(pc) {
            top: 40%;
            left: 32%;
          }

          @include bp(sp) {
            top: 24%;
            right: -1rem;
            width: 42px;
          }
        }
        &05 {
          @include bp(pc) {
            right: 21%;
            bottom: -1rem;
          }
          @include bp(sp) {
            bottom: 40%;
            left: 21%;
            width: 37px;
          }
        }
        &06 {
          @include bp(pc) {
            top: 13%;
            right: 17%;
          }
          @include bp(sp) {
            right: 7rem;
            bottom: 19%;
            width: 23px;
          }
        }
        &07 {
          @include bp(pc) {
            right: 14%;
            bottom: -3rem;
          }
          @include bp(sp) {
            bottom: -1rem;
            left: 7rem;
            width: 55px;
          }
        }
        &08 {
          @include bp(pc) {
            right: 48%;
            bottom: 43%;
          }
          @include bp(sp) {
            right: 36%;
            bottom: 16%;
            width: 25px;
          }
        }
      }
    } //__img
  }
}

/* ------------------------
 Content
------------------------ */
.top {
}
