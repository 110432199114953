/* pagetop
----------------------------------------------------------------- */
@include bp(pc) {
  .pagetop {
    position: absolute;
    top: 5px;
    right: 100px;
    // right: -70px;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    a {
      position: relative;
      &::after {
        position: absolute;
        top: 96px;
        right: -52px;
        width: 60px;
        font-size: 12px;
        font-weight: bold;
        color: #fff;
        content: "page top";
        transform: rotate(90deg);
        @include shuei;
      }

      .line {
        position: absolute;
        top: 20px;
        right: -21px;
        z-index: 10;
        width: 2px;
        height: 50px;
        overflow: hidden;
        &::before {
          display: block;
          width: 2px;
          height: 50px;
          content: " ";
          background: #fff;
        }
      }

      .dot {
        position: absolute;
        top: 16px;
        right: -24px;
        z-index: 10;
        width: 8px;
        height: 8px;
        font-size: 10px;
        content: " ";
        background: #fff;
        border-radius: 50px;
      }
    }

    &:hover {
      a {
        .line {
          &::before {
            animation-name: pageTopLine;
            animation-duration: 0.8s;
            @keyframes pageTopLine {
              0% {
                transform: translateY(0);
              }
              25% {
                background-color: #fff;
                transform: translateY(-100%);
              }
              26% {
                background-color: transparent;
              }
              27% {
                transform: translateY(100%);
              }
              40% {
                background-color: #fff;
              }
              100% {
                transform: translateY(0);
              }
            }
          }
        }
        // .dot{
        //   transform: scale(1.6, 1.6);
        //   transition: all .4s ease;
        // }
      }
    }
  }
}
@include bp(sp) {
  display: none;
}
