/* ------------------------------------------------------------------
 link
------------------------------------------------------------------ */
.c-iconSankakuLink {
  position: relative;
  &::after {
    position: absolute;
    top: 50%;
    right: 0;
    z-index: 10;
    display: inline-block;
    font-family: "fontello";
    font-size: 150%;
    content: "";
    transition: 0.2s ease-in-out;
    transform: translateY(-52%);
    @include bp(sp) {
      right: 1rem;
      font-size: 120%;
    }
  }
}

.c-icon_blank {
  position: relative;
  &::after {
    width: 20px;
    height: 20px;
    content: "";
    background: url(../img/common/icon_link_blank_white.svg) no-repeat 0 0 /
      20px auto;
  }
}
.c-icon_mail {
  position: relative;
  &::after {
    width: 20px;
    height: 20px;
    content: "";
    background: url(../img/common/icon_link_mail_white.svg) no-repeat 0 0 / 20px
      auto;
  }
}
a.txtlink {
  display: inline-block;
  padding-bottom: 0.5rem;
  line-height: 1;
  border-bottom: 1px solid $text-color;
}
