/* ------------------------------------------------------------------
 スライダー
------------------------------------------------------------------ */

/* ----------------
 swiper_adjust
 ---------------- */
.swiper-pagination {
  .swiper-pagination-bullet {
    margin: 0 6px !important;
    // background-color: $bg-color;
    opacity: 1;
  }
  .swiper-pagination-bullet-active {
    background-color: $main-color;
  }
}

.swiper-button-prev,
.swiper-button-next {
  // width: 36px;
  // height: 36px;
  // background-color: $bg-color;
  // border-radius: 50%;
  transition: 0.2s ease-in-out;
  // &::after{
  // 	color: $main-color;
  // 	font-size: 2rem;
  // 	transition: .2s ease-in-out;
  // }

  // @include bp(pc) {
  // 	&:hover{
  // 		background-color: $main-color !important;
  // 		&::after{
  // 			color: $sub-color;
  // 		}
  // 	}
  // }
}

.swiper-button-prev {
  left: 10px;
  &::after {
    z-index: 1;
    width: 30px;
    height: 30px;
    background: url(../img/common/arrow-white.svg) no-repeat;
    background-size: cover;
  }
  &:hover {
    &::after {
      background: url(../img/common/arrow-pink.svg) no-repeat;
    }
  }
}

.swiper-button-next {
  right: 10px;
  &::after {
    z-index: 1;
    width: 30px;
    height: 30px;
    background: url(../img/common/arrow-white.svg) no-repeat;
    background-size: cover;
    transform: rotate(180deg);
  }
  &:hover {
    &::after {
      background: url(../img/common/arrow-pink.svg) no-repeat;
    }
  }
}
