/* ------------------------------------------------------------------
 Nav Trigger
------------------------------------------------------------------ */
.c-navTrigger__open {
  opacity: 0;
}
.wf-active {
  .c-navTrigger__open {
    opacity: 1;
  }
}

.c-navTrigger {
  position: fixed;
  top: 23px;
  right: 23px;
  z-index: $z-navTrigger;
  width: 40px;
  height: 40px;
  padding: 2.5rem;
  cursor: pointer;
  background-color: $white;
  border: 1px solid $main-color;
  border-radius: 50%;

  @include bp(pc) {
    top: 27px;
    right: 40px;
    display: none;

    &:hover {
      span {
        &:nth-child(1) {
          &::before {
            left: 0;
            content: "";
          }
        }
        &:nth-child(2) {
          &::before {
            right: 19px;
          }
          &::after {
            right: 19px;
          }
        }
      }
    }
  }
  @include bp(sp) {
    display: block;
  }

  span {
    position: absolute;
    left: 50%;
    display: block;
    width: 24px;
    height: 3px;
    background: $main-color;
    border-radius: 15px;
    transition: 0.2s ease-in-out;
    transform: translateX(-50%) rotate(0);
    // &::before,&::after{
    // 	content: '';
    // 	transition: .2s ease-in-out;
    // 	width: 1px;
    // 	height: 3px;
    // 	background: $main-color;
    // 	position: absolute;
    // 	display: inline-block;
    // 	border-radius: 20px;
    // 	right: 2rem;

    // }
    &:nth-child(1) {
      top: 15px;
    }
    &:nth-child(2) {
      top: 24px;
    }
  }

  &__open {
    transition: 0.2s ease-in-out;
    // opacity: 1;
    .is-active & {
      opacity: 0;
    }
  }

  &__close {
    // color: $white !important;
    opacity: 0;
    transition: 0.2s ease-in-out;
    .is-active & {
      opacity: 1;
    }
  }

  p {
    @include shuei;

    position: absolute;
    bottom: 0;
    bottom: 0.9rem;
    left: 50%;
    left: 50%;
    font-size: 10px;
    font-weight: bold;
    line-height: 1;
    color: $main-color;
    transform: translateX(-50%);
  }

  &.is-active {
    span {
      background: $main-color !important;
      &:nth-child(1) {
        top: 19px;
        transform: translateX(-50%) rotate(-135deg);
        &::before {
          opacity: 0;
        }
      }
      &:nth-child(2) {
        top: 19px;
        transform: translateX(-50%) rotate(135deg);
        &::before {
          opacity: 0;
        }
        &::after {
          opacity: 0;
        }
      }
    }
    .l-page & {
      span {
        background: $white;
      }
    }
  }
}
