/* ------------------------------------------------------------------
 loading
------------------------------------------------------------------ */

.c-loading {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $z-loading;
  width: 100%;
  height: 100vh;
  transition: 0.6s ease-in-out;
  &.is-hide {
    z-index: -1;
    opacity: 0;
  }
  @media all and (-ms-high-contrast: none) {
    display: none;
  }
}
