/* ------------------------------------------------------------------
 Section　
------------------------------------------------------------------ */
.l-section {
  position: relative;
  width: 100%;
  margin: 0 auto;
  @include bp(pc) {
    padding: 5rem 0;
  }
  @include bp(sp) {
    padding: 2.5rem 0;
  }
  &:last-child {
    padding-bottom: 0;
  }

  &__inner {
    position: relative;
    height: 100%;
    margin: 0 auto;
    @include bp(sp) {
      max-width: 100%;
      padding: 0 $spGutter;
    }

    &--large {
      @extend .l-section__inner;
      @include bp(pc) {
        width: $vw-width-l;
        max-width: $width-l;
      }
    }

    &--middle {
      @extend .l-section__inner;
      @include bp(pc) {
        width: $vw-width-m;
        max-width: $width-m;
      }
    }

    &--small {
      @extend .l-section__inner;
      @include bp(pc) {
        width: $vw-width-s;
        max-width: $width-s;
      }
    }
    &--wysiwyg {
      @extend .l-section__inner;
      @include bp(pc) {
        width: $vw-width-wysiwyg;
        max-width: $width-wysiwyg;
      }
    }
    &--wysiwyg-wide {
      @extend .l-section__inner;
      @include bp(pc) {
        width: $vw-width-wysiwyg-wide;
        max-width: $width-wysiwyg-wide;
      }
    }

    &--fluid {
      @extend .l-section__inner;
      @include bp(sp) {
        padding: 0;
      }
    }
  }
}
