/* ------------------------------------------------------------------
 button
------------------------------------------------------------------ */
.c-btn {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 300px;
  padding: 12px 0 14px;
  overflow: hidden;
  font-size: 1.5rem;
  font-weight: bold;
  color: $white;
  text-align: center;
  background-color: $main-color;
  border: 1px solid $main-color;
  border-radius: 30px;
  transition: 0.2s ease-in-out;
  @include bp(pc) {
    padding: 10px 0 10px;

    &:hover {
      background-color: $bg-color-white;
      border: 1px solid $main-color;
      span {
        color: $main-color;
      }
      &::after {
        color: $main-color;
      }
    }
  }
  @include bp(sp) {
    max-width: 300px;
    // line-height: 1.6;
  }

  span {
    position: relative;
    z-index: 10;
    color: $white;
    transition: 0.2s ease-in-out;
  }

  &::after {
    @include fontello("\e806");

    position: absolute;
    top: 50%;
    right: 19px;
    z-index: 10;
    display: inline-block;
    transition: 0.2s ease-in-out;
    transform: translateY(-50%);
  }
  &.icon_none::after {
    display: none;
  }

  &.icon_blank {
    &::after {
      width: 20px;
      height: 20px;
      content: "";
      background: url(../img/common/icon_link_blank_white.svg) no-repeat 0 0 /
        20px auto;
    }
    &:hover {
      &::after {
        background: url(../img/common/icon_link_blank_orange.svg) no-repeat 0 0 /
          20px auto;
      }
    }
  }
  &.icon_mail {
    &::after {
      width: 17px;
      height: 12px;
      content: "";
      background: url(../img/common/icon_link_mail_white.svg) no-repeat 0 0 /
        17px auto;
    }
    &:hover {
      &::after {
        background: url(../img/common/icon_link_mail_orange.svg) no-repeat 0 0 /
          17px auto;
      }
    }
  }

  &.icon_time::after {
    right: inherit;
    left: 19px;
    width: 20px;
    height: 20px;
    content: "";
    background: url(../img/common/icon_time.svg) no-repeat 0 0 / 20px auto;
  }

  &.icon_calender::after {
    right: inherit;
    left: 19px;
    width: 20px;
    height: 20px;
    content: "";
    background: url(../img/common/icon_calendar.svg) no-repeat 0 0 / 20px auto;
  }

  &.icon_back::after {
    @include fontello("\e805");

    right: inherit;
    left: 19px;
  }

  &.icon_search::after {
    @include fontello("\e80f");
  }

  &.icon_pdf::after {
    position: absolute;
    top: 50%;
    display: inline-block;
    width: 24px;
    padding: 0 1px;
    font-size: 0.8rem;
    font-weight: 800;
    color: $sub-color;
    content: "PDF";
    background-color: $main-color;
    border-radius: 2px;
    transform: translateY(-50%);
    @include gothic;
  }

  &.is-eventnone {
    &::after {
      display: none;
    }
  }

  // &.whiteBgBtn {
  // 	border-radius: 1px solid $main-color;
  // 	background-color: $white;
  // 	span {
  // 		color: $main-color;
  // 	}
  // }
}

.c-btn_w {
  background-color: $white;
}

.c-btn_sub {
  position: relative;
  display: inline-flex;
  justify-content: center;
  padding: 1rem 2rem;
  line-height: 1;
  color: $main-color;
  text-align: center;
  letter-spacing: 0.01em;
  background-color: $white;
  border: 1px solid $main-color;
  border-radius: 30px;
  @include shuei;
}

.c-btn2 {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 300px;
  padding: 12px 0 14px;
  overflow: hidden;
  font-size: 1.5rem;
  font-weight: bold;
  color: $main-color;
  text-align: center;
  background-color: $white;
  border: 1px solid $main-color;
  border-radius: 30px;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;

  // &::after{
  // 	@include fontello("\e806");
  // 	display: inline-block;
  // 	position: absolute;
  // 	top: 46%;
  // 	right: 0;
  // 	transform: translateY(-50%);
  // 	font-size: 2.2rem;
  // 	color: $main-color;
  // }

  &__line {
    position: absolute;
    bottom: -6px;
    left: 0;
    width: 100%;
    height: 6px;
    overflow: hidden;
    background-color: $sub-color;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 0;
      height: 6px;
      content: "";
      background-color: $main-color;
      transition: 0.2s ease-in-out;
    }
  }

  @include bp(pc) {
    &:hover {
      .c-btn2__line {
        &::before {
          width: 100%;
        }
      }
    }
  }
}
