/* ------------------------------------------------------------------
 gNav
------------------------------------------------------------------ */
@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes slideOpen {
  0% {
    // width: 0;
    transform: translateX(360px);
  }
  100% {
    // width: 85.333333333%;
    transform: translateX(0);
  }
}
@keyframes slideClose {
  0% {
    // width: 85.333333333%;
    transform: translateX(0);
  }
  100% {
    // width: 0;
    transform: translateX(360px);
  }
}
.l-gNav {
  position: fixed;
  top: 0;
  right: 0;
  z-index: $z-gNav;
  display: none;
  width: 100%;
  height: 100%;
  @include shuei;
  &.is-open {
    display: block;
    animation: show 0.4s linear 0s;
  }

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    display: block;
    width: 100%;
    height: 100%;
    content: "";
    background-color: rgba(0, 0, 0, 0.6);
    opacity: 0;
    transition: 1s ease-in-out;
  }
  &.is-open::before {
    opacity: 1;
  }

  &__home {
    margin-bottom: 65px;
    white-space: nowrap;
    a {
      display: inline-block;
      font-size: 1.4rem;
      font-weight: bold;
      color: $white;
      // position: relative;
      // padding-left: 26px;
      transition: 0.2s ease-in-out;
      // &::before{
      // 	@include fontello("\e809");
      // 	display: inline-block;
      // 	color: $white;
      // 	position: absolute;
      // 	top: 50%;
      // 	left: 0;
      // 	transform: translateY(-50%);
      // 	transition: .2s ease-in-out;
      // }
      // @include bp(pc) {
      // 	&:hover{
      // 		color: $sub-color;
      // 		&::before{
      // 			color: $sub-color;
      // 		}
      // 	}
      // }
      img {
        max-width: 102px;
      }
    } //a
  } //&__home

  &__inner {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    // max-width: 344px;
    // width: 85.333333333%;
    width: 100%;
    height: 100%;
    padding: 35px 20px 0 30px;
    background-color: $bg-color-white;
    transform: translateX(0);
    .is-open & {
      animation: slideOpen 0.4s linear 0s;
      // width: 85.333333333%;
      // transform: translateX(0);
      @include bp(pc) {
        animation: slideOpen 0.4s linear 0s;
      }
    }
    .is-close & {
      // width: 0;
      transform: translateX(470px);
      animation: slideClose 0.4s linear 0s;
      @include bp(pc) {
        animation: slideClose 0.4s linear 0s;
      }
    }
    &::before {
      // background: url(../img/common/zigzag-g_left@2x.png) repeat-y 0 0 / 10px auto;
      position: absolute;
      top: 0;
      left: -10px;
      display: block;
      width: 10px;
      height: 100%;
      content: "";
    }
  } //.l-gNav__inner

  &__scroll {
    position: relative;
    z-index: 20;
    width: 100%;
    height: 100%;
    padding-right: 10px;
    padding-bottom: 23px;
    overflow-y: scroll;
    &Inner {
      position: relative;
      @include bp(pc) {
        min-height: 830px;
        padding-bottom: 140px;
        // height: 100%;
      }
    }
  } //.l-gNav__scroll

  &__list {
    font-size: 1.6rem;
    font-weight: bold;
  }

  &__item {
    position: relative;
    & + .l-gNav__item {
      margin-top: 12px;
    }
  } ////.l-gNav_item

  &__link {
    font-size: 2rem;
    font-weight: bold;
    color: $main-color;
    white-space: nowrap;
    transition: 0.2s ease-in-out;
    @include bp(pc) {
      &:hover {
        color: $sub-color;
      }
    }
    &__hoverNon {
      @include bp(pc) {
        &:hover {
          color: $main-color;
        }
      }
    }
  } //.l-gNav__link

  &__sub {
    margin-top: 0.75rem;
    font-size: 2rem;
    font-weight: bold;
    color: $main-color;
    white-space: nowrap;
    transition: 0.2s ease-in-out;

    &Item {
      & + .l-gNav__subItem {
        margin-top: 10px;
      }
    }
    &Link {
      position: relative;
      font-size: 1.6rem;
      transition: 0.2s ease-in-out;
      @include bp(pc) {
        &:hover {
          color: $sub-color;
        }
      }
      &::before {
        content: "∟";
      }
    } //l-gNav__subLink
    &En {
      @include shuei;

      padding-bottom: 5px;
      margin-top: 7px;
      font-size: 1.6rem;
      font-weight: bold;
      line-height: 1;
      white-space: nowrap;
    }
    &Jp {
      font-size: 1.2rem;
      font-weight: bold;
      line-height: 1.2;
      white-space: nowrap;
    }
  } //.l-gNav__sub

  &__center {
    margin-top: 30px;
    .l-gNav__link {
      font-size: 1.4rem;
    }
  }

  &__btm {
    width: 70%;
    margin-top: 35px;
    .c-btn {
      padding: 12px 0 13px;
      color: $main-color;
      white-space: nowrap;
      background-color: $bg-color-white;
      border: 1px solid $main-color;
      @include bp(sp) {
        width: 150px;
        line-height: 1;
      }

      &::after {
        display: none;
      }

      span {
        font-size: 1.5rem;
        color: $main-color;
      }
      & + .c-btn {
        margin-top: 10px;
      }
    }
  } //.l-gNav__btm

  &__fix {
    margin-top: 30px;
    @include bp(pc) {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
    & > .c-flex {
      align-items: flex-end;
    }
    &Sns {
      width: 43%;
      p {
        @include shuei;

        font-size: 1.2rem;
        font-weight: bold;
        color: $main-color;
      }

      .c-sns {
        display: flex;
        margin-top: 15px;
        &__link::before {
          color: $gray;
        }
        &__fb::before {
          font-size: 2.8rem;
        }
        &__insta::before {
          font-size: 3.1rem;
        }
        &__tw::before {
          margin-top: -0.25rem;
          font-size: 3.3rem;
        }
        li {
          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      } //c-sns
    } //_fixsns

    &Img {
      flex: 1;
      padding-left: 20px;
    }
  }

  &__chara {
    position: relative;
    width: 100%;
    height: 90px;
    &Box {
      position: absolute;
      right: 0;
      bottom: 0;
      display: flex;
      flex-wrap: nowrap;
      align-items: flex-end;
      justify-content: flex-end;
      overflow: hidden;
      opacity: 1;
      // &.is-active{
      // 	opacity: 1;
      // }
      &#chara1 {
        right: 5.5rem;
        img {
          width: 40px;
        }
      }
      &#chara2 {
        right: 0;
        img {
          width: 37px;
        }
      }
    }
    &Name {
      @include shuei;

      font-size: 1.2rem;
      font-weight: bold;
      line-height: 1;
      color: $white;
    }
  }
}
