.fadeIn {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s linear, visibility 0.3s linear, transform 0.3s linear;
  transform: translate(0, 20px);

  &.late1 {
    transition-delay: 0.3s;
  }

  &.late2 {
    transition-delay: 0.4s;
  }

  &.late3 {
    transition-delay: 0.5s;
  }

  &.late4 {
    transition-delay: 0.6s;
  }

  &.late5 {
    transition-delay: 0.7s;
  }

  &.late6 {
    transition-delay: 0.8s;
  }

  &.late7 {
    transition-delay: 0.9s;
  }

  &.late8 {
    transition-delay: 1s;
  }
  @include bp(pc) {
    &.pcLate1 {
      transition-delay: 0.3s;
    }

    &.pcLate2 {
      transition-delay: 0.4s;
    }

    &.pcLate3 {
      transition-delay: 0.5s;
    }

    &.pcLate4 {
      transition-delay: 0.6s;
    }

    &.pcLate5 {
      transition-delay: 0.7s;
    }

    &.pcLate6 {
      transition-delay: 0.8s;
    }

    &.pcLate7 {
      transition-delay: 0.9s;
    }

    &.pcLate8 {
      transition-delay: 1s;
    }
  }

  &.is-fade {
    visibility: visible;
    opacity: 1;
    transform: translate(0, 0);
  }
}
