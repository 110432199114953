/* ------------------------------------------------------------------
 記事ページネーション
------------------------------------------------------------------ */

/* ------------------------
 pagenation
------------------------ */
.c-pagenation {
  margin-top: 50px;
  .wp-pagenavi {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    a,
    span {
      @include shuei;

      display: inline-block;
      width: 30px;
      height: 30px;
      margin: 5px 10px;
      font-size: 1.7rem;
      font-weight: bold;
      line-height: 30px;
      color: #ccc;
      text-align: center;
      @include bp(pc) {
        width: 36px;
        height: 36px;
        margin: 5px 15px;
        line-height: 36px;
      }
    }
    .current {
      color: $main-color;
    }
    .previouspostslink,
    .nextpostslink {
      position: relative;
      width: 36px;
      height: 36px;
      font-size: 0;
      line-height: 36px;
      color: $main-color;
      background-color: $white;
      border-radius: 50%;
      @include bp(pc) {
        width: 40px;
        height: 40px;
        line-height: 40px;
      }
      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        display: inline-block;
        font-size: 2rem;
        transform: translate(-50%, -50%);
      }
    }
    .previouspostslink {
      margin: 5px 13px 5px 0;
      @include bp(pc) {
        margin: 5px 20px 5px 0;
      }
      &::before {
        @include fontello("\e805");
      }
    }
    .nextpostslink {
      margin: 5px 0 5px 13px;
      @include bp(pc) {
        margin: 5px 0 5px 20px;
      }
      &::before {
        @include fontello("\e806");
      }
    }
  }
}
