/* ------------------------------------------------------------------
 news   共通
------------------------------------------------------------------ */

/* ------------------------
 p-commonPage
------------------------ */

//
// ========================================
// layout
// ========================================

$col-max: 12; //何分割するか
$col-margin: 5px; //カラム間のmarginをpxにするか（あけたい間隔の半分）
$col-margin-wide: 10px; //カラム間のmargin（幅広）

$col-margin-05: 5px; //カラム間のmargin
$col-margin-10: 10px; //カラム間のmargin
$col-margin-15: 15px; //カラム間のmargin
$col-margin-20: 20px; //カラム間のmargin
$col-margin-25: 25px; //カラム間のmargin
$col-margin-30: 30px; //カラム間のmargin
$col-margin-35: 35px; //カラム間のmargin
$col-margin-40: 40px; //カラム間のmargin
$col-margin-45: 45px; //カラム間のmargin
$col-margin-95: 95px; //カラム間のmargin

/**
* layout
* ----------------------------------
*/

.wrapColumn {
  display: flex;
  flex-wrap: wrap;

  > [class*="col"] {
    box-sizing: border-box;
  }

  &.pos-center {
    justify-content: center;
  }
  &.pos-right {
    justify-content: flex-end;
  }
  &.pos-space-between {
    justify-content: space-between;
  }

  &.align-center {
    align-items: center;
  }
  &.align-bottom {
    align-items: flex-end;
  }

  &.order-reverse {
    flex-direction: row-reverse;
  }

  &.has-margin {
    box-sizing: border-box;
    margin-right: -$col-margin;
    margin-left: -$col-margin;

    > [class*="col"] {
      padding-right: $col-margin;
      padding-left: $col-margin;
    }
  }

  &.has-margin-wide {
    box-sizing: border-box;
    margin-right: -$col-margin-wide;
    margin-left: -$col-margin-wide;

    > [class*="col"] {
      padding-right: $col-margin-wide;
      padding-left: $col-margin-wide;
    }
  }

  &.has-margin-05 {
    box-sizing: border-box;
    margin-right: -$col-margin-05;
    margin-left: -$col-margin-05;

    > [class*="col"] {
      padding-right: $col-margin-05;
      padding-left: $col-margin-05;
    }
  }
  &.has-margin-10 {
    box-sizing: border-box;
    margin-right: -$col-margin-10;
    margin-left: -$col-margin-10;

    > [class*="col"] {
      padding-right: $col-margin-10;
      padding-left: $col-margin-10;
    }
  }
  &.has-margin-15 {
    box-sizing: border-box;
    margin-right: -$col-margin-15;
    margin-left: -$col-margin-15;

    > [class*="col"] {
      padding-right: $col-margin-15;
      padding-left: $col-margin-15;
    }
  }
  &.has-margin-20 {
    box-sizing: border-box;
    margin-right: -$col-margin-20;
    margin-left: -$col-margin-20;

    > [class*="col"] {
      padding-right: $col-margin-20;
      padding-left: $col-margin-20;
    }
  }
  &.has-margin-25 {
    box-sizing: border-box;
    margin-right: -$col-margin-25;
    margin-left: -$col-margin-25;

    > [class*="col"] {
      padding-right: $col-margin-25;
      padding-left: $col-margin-25;
    }
  }
  &.has-margin-30 {
    box-sizing: border-box;
    margin-right: -$col-margin-30;
    margin-left: -$col-margin-30;

    > [class*="col"] {
      padding-right: $col-margin-30;
      padding-left: $col-margin-30;
    }
  }
  &.has-margin-35 {
    box-sizing: border-box;
    margin-right: -$col-margin-35;
    margin-left: -$col-margin-35;

    > [class*="col"] {
      padding-right: $col-margin-35;
      padding-left: $col-margin-35;
    }
  }
  &.has-margin-40 {
    box-sizing: border-box;
    margin-right: -$col-margin-40;
    margin-left: -$col-margin-40;

    > [class*="col"] {
      padding-right: $col-margin-40;
      padding-left: $col-margin-40;
    }
  }
  &.has-margin-45 {
    box-sizing: border-box;
    margin-right: -$col-margin-45;
    margin-left: -$col-margin-45;

    > [class*="col"] {
      padding-right: $col-margin-45;
      padding-left: $col-margin-45;
    }
  }
  &.has-margin-95 {
    box-sizing: border-box;
    margin-right: -$col-margin-95;
    margin-left: -$col-margin-95;

    > [class*="col"] {
      padding-right: $col-margin-95;
      padding-left: $col-margin-95;
    }
  }
  .wp-block-image{
    margin-bottom: 1em;
  }
}

@for $i from 1 through $col-max {
  $col-num: $col-max / $i;
  .wrapColumn > .col-#{$i} {
    width: floor($i / $col-max * 10000%) / 100;
  }
  @include bp(sp) {
    .wrapColumn > .col-sp-#{$i} {
      width: floor($i / $col-max * 10000%) / 100;
    }
  }
  @include bp(pc) {
    .wrapColumn > .col-tb-#{$i} {
      width: floor($i / $col-max * 10000%) / 100;
    }
  }
}
