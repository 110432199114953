/* ------------------------------------------------------------------
 header
------------------------------------------------------------------ */
header {
  width: 150px;
  @include bp(pc) {
    position: fixed;
    left: 0;
    z-index: 99999999;
  }
}
.l-header {
  @include shuei;
  // width: 100%;
  // height: 80px;
  @include bp(pc) {
    // height: 90px;
  }

  &__inner {
    // width: 100%;
    @include bp(pc) {
      display: flex;
      // flex-wrap: wrap;
      // justify-content: space-between;
      align-items: center;
      // flex-direction: column;
      height: 100vh;
      min-height: 660px;
      letter-spacing: 0.1em;

      // background-color: $white;
      writing-mode: vertical-rl;
    }
    // @include bp(sp) {
    //     display: none;
    // }
  }

  &__logo {
    padding-top: 16px;
    // padding-left: 20px;
    @include bp(pc) {
      padding-top: 21px;
      // padding-left: 40px;
    }
    @include bp(sp) {
      margin-left: 2rem;
    }
    a {
      display: block;
    }
    img {
      // width: 180px;
      @include bp(pc) {
        width: 102px;
      }
    }
  }

  &__nav {
    height: 100%;

    @include bp(pc) {
      display: flex;
      align-items: center;
      padding-right: 2.5rem;
      padding-left: 2.5rem;
    }
    @include bp(sp) {
      display: none;
    }

    &List {
      display: flex;
      align-items: center;
      width: 100%;
      margin-top: 45px;
    }

    &Item {
      position: relative;
      padding-bottom: 30px;
      margin: 0 15px;
      @include bp(pc) {
        &#headerNav__trigger {
          .l-header__navLink {
            &:hover {
              // transform: translatex(-48px);
              // left: -48px;
            } //hover

            &::before {
              width: 100%;
            }
          }
        } //#headerNav__trigger
      } //@include bp(pc)
    } //Item

    &Link {
      position: relative;
      // color: $main-color;
      font-size: 1.4rem;
      font-weight: bold;
      transition: 0.2s ease-in-out;

      // &::before{
      //     content: "";
      //     display: inline-block;
      //     position: absolute;
      //     bottom: -10px;
      //     left: 0;
      //     width: 0;
      //     height: 5px;
      //     background-color: $sub-color;
      //     transition: .2s ease-in-out;
      // }

      @include bp(pc) {
        &:hover {
          color: $main-color;
          // &::before{
          //     width: 100%;
          // }
          // @media all and (-ms-high-contrast: none){
          //     color: $sub-color;
          //     &::before{
          //         width: 0;
          //     }
          // }
        }
      }
    }

    &Low {
      // background-color: $main-color;
      position: absolute;
      // top: 1em;
      top: 0;
      left: 0;
      z-index: -1;
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-right: 10px;
      padding-left: 10px;
      border-radius: 0 0 8px 8px;
      opacity: 0;
      transition: 0.2s ease-in-out;
      // transform: translateY(-10px);
      transform: translateX(0);
      // transform: translatex(30px);
      transform: translateX(80px);
      &.is-active {
        z-index: 100;
        opacity: 1;
        // transform: translateY(0);
        // transform: translatex(30px);
        transform: translateX(80px);
      }
      li {
        padding-right: 0.2rem;
        padding-left: 0.2rem;
      }

      // &_item {
      //     & + .l-header__navLow_item{
      //         border-top: 1px solid $white;
      //     }
      // }
      &_link {
        display: block;
        padding: 0 2px;
        font-size: 1.2rem;
        font-weight: bold;
        line-height: 1.2;
        transition: 0.2s ease-in-out;
        @include bp(pc) {
          &:hover {
            color: $main-color;
          }
        }
      }
    }
    &__btm {
      display: flex;
      @include bp(pc) {
        position: relative;
        // position: fixed;
        bottom: 6rem;
        margin-top: auto;
      }

      &__btn {
        .c-btn {
          display: flex;
          justify-content: center;
          padding: 1rem 2rem;
          text-align: center;
          letter-spacing: 0.01em;
          background-color: $white;
          border: 1px solid $main-color;
          span {
            font-size: 1.1rem;
            line-height: 0.5em;
            color: $main-color;
            writing-mode: horizontal-tb;
          }
          &::after {
            display: none;
          }
        } //c-btn
      }
      &__sns {
        display: flex;
        align-items: center;
        margin-top: 2rem;
        .c-sns {
          display: flex;
          flex-direction: column;
          align-items: center;
          &__item {
            padding-right: 0.3rem;
            padding-left: 0.3rem;
          }
        }
        .c-sns__link {
          &:hover {
            &::before {
              color: $main-color;
            }
          }
          &::before {
            color: $gray;
          }
        } //c-sns__link

        .is-footerColorChange {
          .c-sns__link::before {
            color: $white;
          }
        } //is-footerColorChange
      } //__sns
    } //l-header__nav__btm
  } //__nav
  .current {
    color: $main-color;
  }
}
