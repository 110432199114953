/* ------------------------------------------------------------------
 body container wrapper main
------------------------------------------------------------------ */

.l-body {
  &.is-stop {
    overflow: hidden;
  }
}
// wrapper
.l-wrapper {
  position: relative;
  overflow-x: hidden;
}
// container
.l-container {
  @include bp(pc) {
    padding-bottom: 20rem;
  }

  @include bp(sp) {
    padding-bottom: 14.6rem;
  }
}
// main
.l-main {
  position: relative;
  @include bp(sp) {
    overflow: hidden;
  }
}

// page
.l-page {
}
@media screen and (min-height: 995px) {
  .smallHeightContent {
    #footer {
      position: fixed;
      bottom: 0;
    }
    #footerColorChangeTrigger {
      .c-sns__link::before {
        color: #fff;
      }
    }
  }
}
