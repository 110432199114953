/* ------------------------------------------------------------------
 Variables
------------------------------------------------------------------ */
// color
$text-color: #707070;
$link-color: #006c54;

$main-color: #ea7155;
$sub-color: #fdea89;
$bg-color-yellow: #fcf6d7;
$bg-color-gray: #f0f0f0;
$bg-color-white: #fff;

$bg-color-wysiwyg-gray: #f0f0f0;

$white: #fff;
$black: #000;
$gray: #c6c6c6;
$red: #f00;

$archive-gray: #9a9a9a;

//gutter
$pcGutter: 3.660322108vw;
$spGutter: 5.333333vw;

// content width
$width-l: 1200px;
$vw-width-l: 85.714285714vw;
$width-m: 1080px;
$vw-width-m: 67.14285714vw;
$width-s: 940px;
$vw-width-s: 67.1428571428vw;

$width-wysiwyg: 742px;
$vw-width-wysiwyg: 53vw;

$width-wysiwyg-wide: 872px;
$vw-width-wysiwyg-wide: 62.285714285vw;

// z-index
$z-header: 2000;
$z-navTrigger: 2500;
$z-gNav: 2100;
$z-modal: 3000;
$z-loading: 9999;
$z-areahours: 10000;

// top-moveItem
$move_gion: (320 / 1400) * 100 + vw;
$move_gion-w: (380 / 1400) * 100 + vw;
$move_gion-anim-w: (580 / 1400) * 100 + vw;
