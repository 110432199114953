/* ------------------------------------------------------------------
 tableレイアウト
------------------------------------------------------------------ */
.c-table {
  &__row {
    & + .c-table__row {
      margin-top: 4px;
    }
    @include bp(pc) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  &__radius {
    border-radius: 5px 5px 0 0;
    @include bp(pc) {
      border-radius: 0;
    }
  }

  &__th {
    padding: 8px 16px 11px;
    background-color: $main-color;
    @include bp(pc) {
      display: flex;
      display: -webkit-flex;
      align-items: center;
      justify-content: center;
      width: 166px;
    }
    &Title {
      font-weight: bold;
      color: $white;
    }
  }

  &__td {
    padding: 16px 16px 10px;
    margin-top: 4px;
    background-color: $white;
    @include bp(pc) {
      display: flex;
      align-items: center;
      width: calc(100% - 170px);
      padding: 27px 25px 24px;
      margin-top: 0;
    }
  }
}
