/* ------------------------------------------------------------------
 aside
------------------------------------------------------------------ */
.l-aside {
  padding: 50px 0 30px;
  @include bp(pc) {
    padding: 84px 0 60px;
  }
  &__list {
    margin-top: 35px;
    @include bp(pc) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 65px;
    }

    .l-page & {
      display: none;
    }
  }
  &__item {
    @include bp(pc) {
      width: 31.48%;
      text-align: center;
    }
    & + .l-aside__item {
      margin-top: 7px;
      @include bp(pc) {
        margin-top: 0;
      }
    }
  }
  &__link {
    transition: 0.2s ease-in-out;
    @include bp(pc) {
      display: block;
      padding: 14px 0 17px;
      font-size: 1.3rem;
      background-color: $white;
      border-radius: 5px;

      .c-pdf {
        display: inline-block;
        width: 100%;
        font-size: 1.3rem;
        &::after {
          left: 18px;
        }
        @include bp(pc) {
          padding-left: 0;
        }
      }

      &:hover {
        background-color: $sub-color;
      }
    }
  }

  &__sns {
    position: relative;
    &Pop {
      position: absolute;
      top: -17px;
      left: -3px;
      // left: 5px;
      width: 98px;
      height: 70px;
      @media screen and (min-width: 480px) {
        left: 50%;
        transform: translateX(-190px);
        // transform: translateX(-175px);
      }
      @include bp(pc) {
        top: -26px;
        width: 125px;
        height: 89px;
        transform: translateX(-230px);
        // transform: translateX(-200px);
      }
    }

    &Text {
      position: absolute;
      top: 52%;
      left: 50%;
      width: 100%;
      font-size: 1.1rem;
      font-weight: bold;
      color: $white;
      text-align: center;
      transform: translate(-50%, -50%) rotate(-6deg);
      @include bp(pc) {
        font-size: 1.4rem;
      }
    }

    &Title {
      @include shuei;

      font-size: 2rem;
      font-weight: bold;
      color: $main-color;
      text-align: center;
    }

    &List {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 18px;
    }

    &Item {
      width: 60px;
      margin: 0 5px;
      @include bp(pc) {
        margin: 0 10px;
      }
      a {
        position: relative;
        display: block;
        height: 60px;
        background-color: $white;
        border-radius: 50%;
        transition: 0.2s ease-in-out;
        &::before {
          position: absolute;
          top: 50%;
          left: 50%;
          display: inline-block;
          font-size: 3rem;
          color: $main-color;
          transform: translate(-50%, -50%);
        }
        @include bp(pc) {
          &:hover {
            background-color: $sub-color;
          }
        }
      }
    }

    &Tw {
      &::before {
        @include fontello("\f099");
      }
    }

    &Insta {
      &::before {
        @include fontello("\f16d");
      }
    }

    &Fb {
      &::before {
        @include fontello("\e803");

        font-size: 2.5rem !important;
      }
    }
  }
}
