/* utility CSS */

// 改行 切り替え
br {
  &.pc {
    @include bp(sp) {
      display: none !important;
    }
  }
  &.sp {
    @include bp(pc) {
      display: none !important;
    }
  }
}

// 画像 切り替え
img {
  &.pc {
    @include bp(sp) {
      display: none !important;
    }
  }
  &.sp {
    @include bp(pc) {
      display: none !important;
    }
  }
}

// 表示 切り替え
.display {
  &Pc {
    @include bp(sp) {
      display: none !important;
    }
  }
  &Sp {
    @include bp(pc) {
      display: none !important;
    }
  }
}

// 色
.u-mainColor {
  color: $main-color !important;
}
.u-subColor {
  color: $sub-color !important;
}
.u-white {
  color: $white !important;
}
.u-gray {
  color: $gray !important;
}
.u-black {
  color: $black !important;
}
.u-red {
  color: $red !important;
}

// 背景色
.u-bgWhite {
  background-color: $white !important;
}
.u-bgGray {
  background-color: $gray !important;
}
.u-bgBlack {
  background-color: $black !important;
}
.u-bgMain {
  background-color: $main-color !important;
}
.u-bgSub {
  background-color: $sub-color !important;
}
.u-bgYellow {
  background-color: $bg-color-yellow !important;
}

.is-eventnone {
  pointer-events: none !important;
}
.u-indent {
  padding-left: 1em !important;
  text-indent: -1em !important;
}

.u-pcHide {
  @include bp(pc) {
    display: none !important;
  }
}
.u-spHide {
  @include bp(sp) {
    display: none !important;
  }
}
.u-show {
  display: block !important;
}
.u-hide {
  display: none !important;
}

.u-nolink {
  pointer-events: none !important;
}

.u-b-none {
  border: none !important;
}

/* Font
------------------------------------------------------------------ */

/* Text Decoration */
.u-fw300 {
  font-weight: 300 !important;
}
.u-fw400 {
  font-weight: 400 !important;
}
.u-fw500 {
  font-weight: 500 !important;
}
.u-fw600 {
  font-weight: 600 !important;
}
.u-fw700 {
  font-weight: 700 !important;
}
.u-fNormal {
  font-weight: normal !important;
}
.u-bold {
  font-weight: bold !important;
}

.u-small {
  font-size: 0.8em !important;
}
.u-large {
  font-size: 1.5em !important;
}

.u-underline {
  text-decoration: underline !important;
}

/* Text Position */
.u-tac {
  text-align: center !important;
}

.u-tal {
  text-align: left !important;
}

.u-tar {
  text-align: right !important;
}

.u-vat {
  vertical-align: top !important;
}

.u-vam {
  vertical-align: middle !important;
}

.u-vab {
  vertical-align: bottom !important;
}

.u-vatb {
  vertical-align: text-bottom !important;
}

/* LineHeight */
.u-lh1 {
  line-height: 1 !important;
}
.u-lh12 {
  line-height: 1.2 !important;
}
.u-lh15 {
  line-height: 1.5 !important;
}
.u-lh16 {
  line-height: 1.6 !important;
}
.u-lh17 {
  line-height: 1.7 !important;
}
.u-lh25 {
  line-height: 2.5 !important;
}

/* Nowrap */
.u-nowrap {
  white-space: nowrap !important;
}

/* Text Size */
.u-txt9 {
  font-size: 0.9rem !important;
}

.u-txt10 {
  font-size: 1rem !important;
}

.u-txt11 {
  font-size: 1.1rem !important;
}

.u-txt12 {
  font-size: 1.2rem !important;
}

.u-txt13 {
  font-size: 1.3rem !important;
}

.u-txt14 {
  font-size: 1.4rem !important;
}

.u-txt15 {
  font-size: 1.5rem !important;
}

.u-txt16 {
  font-size: 1.6rem !important;
}

.u-txt17 {
  font-size: 1.7rem !important;
}

.u-txt18 {
  font-size: 1.8rem !important;
}

.u-txt19 {
  font-size: 1.9rem !important;
}

.u-txt20 {
  font-size: 2rem !important;
}

.u-txt21 {
  font-size: 2.1rem !important;
}

.u-txt22 {
  font-size: 2.2rem !important;
}

.u-txt23 {
  font-size: 2.3rem !important;
}

.u-txt24 {
  font-size: 2.4rem !important;
}

.u-txt25 {
  font-size: 2.5rem !important;
}

.u-txt26 {
  font-size: 2.6rem !important;
}

.u-txt27 {
  font-size: 2.7rem !important;
}

.u-txt28 {
  font-size: 2.8rem !important;
}

.u-txt29 {
  font-size: 2.9rem !important;
}

.u-txt30 {
  font-size: 3rem !important;
}

.u-txt10per {
  font-size: 10% !important;
}
.u-txt20per {
  font-size: 20% !important;
}
.u-txt30per {
  font-size: 30% !important;
}
.u-txt40per {
  font-size: 40% !important;
}
.u-txt50per {
  font-size: 50% !important;
}
.u-txt60per {
  font-size: 60% !important;
}
.u-txt70per {
  font-size: 70% !important;
}
.u-txt80per {
  font-size: 80% !important;
}
.u-txt90per {
  font-size: 90% !important;
}

/* Layout
------------------------------------------------------------------ */

/* Float */
.u-fl {
  float: left !important;
}

.u-fr {
  float: right !important;
}

.u-ofh {
  overflow: hidden !important;
}

/* Width */
.u-w5 {
  width: 5% !important;
}

.u-w10 {
  width: 10% !important;
}

.u-w15 {
  width: 15% !important;
}

.u-w20 {
  width: 20% !important;
}

.u-w25 {
  width: 25% !important;
}

.u-w30 {
  width: 30% !important;
}

.u-w35 {
  width: 35% !important;
}

.u-w40 {
  width: 40% !important;
}

.u-w45 {
  width: 45% !important;
}

.u-w50 {
  width: 50% !important;
}

.u-w55 {
  width: 55% !important;
}

.u-w60 {
  width: 60% !important;
}

.u-w65 {
  width: 65% !important;
}

.u-w70 {
  width: 70% !important;
}

.u-w75 {
  width: 75% !important;
}

.u-w80 {
  width: 80% !important;
}

.u-w85 {
  width: 85% !important;
}

.u-w90 {
  width: 90% !important;
}

.u-w95 {
  width: 95% !important;
}

.u-w100 {
  width: 100% !important;
}

/* Block
------------------------------------------------------------------ */
.u-d-block {
  display: block !important;
}

.u-d-inline {
  display: inline !important;
}

.u-d-inline-block {
  display: inline-block !important;
}

.u-d-none {
  display: none !important;
}

.u-d-flex {
  display: flex !important;
}
.u-d-inline-flex {
  display: inline-flex !important;
}

.u-d-flex-harf {
  display: flex !important;
}
.u-d-flex-harf > * {
  width: 50%;
}

/* Padding
------------------------------------------------------------------ */
.u-p0 {
  padding: 0 !important;
}

.u-p10 {
  padding: 10px !important;
}

.u-p20 {
  padding: 20px !important;
}

// padding-top
.u-pt0 {
  padding-top: 0 !important;
}
.u-pt5 {
  padding-top: 5px !important;
}

.u-pt10 {
  padding-top: 10px !important;
}

.u-pt15 {
  padding-top: 15px !important;
}

.u-pt20 {
  padding-top: 20px !important;
}

.u-pt25 {
  padding-top: 25px !important;
}

.u-pt30 {
  padding-top: 30px !important;
}

.u-pt35 {
  padding-top: 35px !important;
}

.u-pt40 {
  padding-top: 40px !important;
}

.u-pt45 {
  padding-top: 45px !important;
}

.u-pt50 {
  padding-top: 50px !important;
}

.u-pt55 {
  padding-top: 55px !important;
}

.u-pt60 {
  padding-top: 60px !important;
}

.u-pt65 {
  padding-top: 65px !important;
}

.u-pt70 {
  padding-top: 70px !important;
}

.u-pt75 {
  padding-top: 75px !important;
}

.u-pt80 {
  padding-top: 80px !important;
}

.u-pt85 {
  padding-top: 85px !important;
}

.u-pt90 {
  padding-top: 90px !important;
}

.u-pt95 {
  padding-top: 95px !important;
}

.u-pt100 {
  padding-top: 100px !important;
}

.u-pt110 {
  padding-top: 110px !important;
}

.u-pt120 {
  padding-top: 120px !important;
}

.u-pt130 {
  padding-top: 130px !important;
}

.u-pt140 {
  padding-top: 140px !important;
}

.u-pt150 {
  padding-top: 150px !important;
}

// padding-bottom
.u-pb0 {
  padding-bottom: 0 !important;
}

.u-pb5 {
  padding-bottom: 5px !important;
}

.u-pb10 {
  padding-bottom: 10px !important;
}

.u-pb15 {
  padding-bottom: 15px !important;
}

.u-pb20 {
  padding-bottom: 20px !important;
}

.u-pb25 {
  padding-bottom: 25px !important;
}

.u-pb30 {
  padding-bottom: 30px !important;
}

.u-pb35 {
  padding-bottom: 35px !important;
}

.u-pb40 {
  padding-bottom: 40px !important;
}

.u-pb45 {
  padding-bottom: 45px !important;
}

.u-pb50 {
  padding-bottom: 50px !important;
}

.u-pb55 {
  padding-bottom: 55px !important;
}

.u-pb60 {
  padding-bottom: 60px !important;
}

.u-pb65 {
  padding-bottom: 65px !important;
}

.u-pb70 {
  padding-bottom: 70px !important;
}

.u-pb75 {
  padding-bottom: 75px !important;
}

.u-pb80 {
  padding-bottom: 80px !important;
}

.u-pb85 {
  padding-bottom: 85px !important;
}

.u-pb90 {
  padding-bottom: 90px !important;
}

.u-pb95 {
  padding-bottom: 95px !important;
}

.u-pb100 {
  padding-bottom: 100px !important;
}

.u-pb110 {
  padding-bottom: 110px !important;
}

.u-pb120 {
  padding-bottom: 120px !important;
}

.u-pb130 {
  padding-bottom: 130px !important;
}

.u-pb140 {
  padding-bottom: 140px !important;
}

.u-pb150 {
  padding-bottom: 150px !important;
}

// padding-left
.u-pl0 {
  padding-left: 0 !important;
}

.u-pl5 {
  padding-left: 5px !important;
}

.u-pl10 {
  padding-left: 10px !important;
}

.u-pl15 {
  padding-left: 15px !important;
}

.u-pl20 {
  padding-left: 20px !important;
}

.u-pl25 {
  padding-left: 25px !important;
}

.u-pl30 {
  padding-left: 30px !important;
}

.u-pl35 {
  padding-left: 35px !important;
}

.u-pl40 {
  padding-left: 40px !important;
}

.u-pl45 {
  padding-left: 45px !important;
}

.u-pl50 {
  padding-left: 50px !important;
}

.u-pl55 {
  padding-left: 55px !important;
}

.u-pl60 {
  padding-left: 60px !important;
}

.u-pl65 {
  padding-left: 65px !important;
}

.u-pl70 {
  padding-left: 70px !important;
}

.u-pl75 {
  padding-left: 75px !important;
}

.u-pl80 {
  padding-left: 80px !important;
}

.u-pl85 {
  padding-left: 85px !important;
}

.u-pl90 {
  padding-left: 90px !important;
}

.u-pl100 {
  padding-left: 100px !important;
}

// padding-right
.u-pr0 {
  padding-right: 0 !important;
}

.u-pr5 {
  padding-right: 5px !important;
}

.u-pr10 {
  padding-right: 10px !important;
}

.u-pr15 {
  padding-right: 15px !important;
}

.u-pr20 {
  padding-right: 20px !important;
}

.u-pr25 {
  padding-right: 25px !important;
}

.u-pr30 {
  padding-right: 30px !important;
}

.u-pr35 {
  padding-right: 35px !important;
}

.u-pr40 {
  padding-right: 40px !important;
}

.u-pr45 {
  padding-right: 45px !important;
}

.u-pr50 {
  padding-right: 50px !important;
}

.u-pr55 {
  padding-right: 55px !important;
}

.u-pr60 {
  padding-right: 60px !important;
}

.u-pr65 {
  padding-right: 65px !important;
}

.u-pr70 {
  padding-right: 70px !important;
}

.u-pr75 {
  padding-right: 75px !important;
}

.u-pr80 {
  padding-right: 80px !important;
}

.u-pr85 {
  padding-right: 85px !important;
}

.u-pr90 {
  padding-right: 90px !important;
}

.u-pr100 {
  padding-right: 100px !important;
}

/* Margin
------------------------------------------------------------------ */
.u-m0a {
  margin: 0 auto !important;
}

.u-m0 {
  margin: 0 !important;
}

.u-m10 {
  margin: 10px !important;
}

.u-m20 {
  margin: 20px !important;
}

// margin-top
.u-mt0 {
  margin-top: 0 !important;
}

.u-mt5 {
  margin-top: 5px !important;
}

.u-mt10 {
  margin-top: 10px !important;
}

.u-mt12 {
  margin-top: 12px !important;
}

.u-mt15 {
  margin-top: 15px !important;
}

.u-mt20 {
  margin-top: 20px !important;
}

.u-mt25 {
  margin-top: 25px !important;
}

.u-mt30 {
  margin-top: 30px !important;
}

.u-mt35 {
  margin-top: 35px !important;
}

.u-mt40 {
  margin-top: 40px !important;
}

.u-mt45 {
  margin-top: 45px !important;
}

.u-mt50 {
  margin-top: 50px !important;
}

.u-mt55 {
  margin-top: 55px !important;
}

.u-mt60 {
  margin-top: 60px !important;
}

.u-mt65 {
  margin-top: 65px !important;
}

.u-mt70 {
  margin-top: 70px !important;
}

.u-mt75 {
  margin-top: 75px !important;
}

.u-mt80 {
  margin-top: 80px !important;
}

.u-mt90 {
  margin-top: 90px !important;
}

.u-mt100 {
  margin-top: 100px !important;
}

.u-mt110 {
  margin-top: 110px !important;
}

.u-mt120 {
  margin-top: 120px !important;
}

.u-mt130 {
  margin-top: 130px !important;
}

.u-mt140 {
  margin-top: 140px !important;
}

.u-mt150 {
  margin-top: 150px !important;
}

// margin-bottom
.u-mb0 {
  margin-bottom: 0 !important;
}

.u-mb5 {
  margin-bottom: 5px !important;
}

.u-mb10 {
  margin-bottom: 10px !important;
}

.u-mb12 {
  margin-bottom: 12px !important;
}

.u-mb15 {
  margin-bottom: 15px !important;
}

.u-mb20 {
  margin-bottom: 20px !important;
}

.u-mb25 {
  margin-bottom: 25px !important;
}

.u-mb30 {
  margin-bottom: 30px !important;
}

.u-mb35 {
  margin-bottom: 35px !important;
}

.u-mb40 {
  margin-bottom: 40px !important;
}

.u-mb45 {
  margin-bottom: 45px !important;
}

.u-mb50 {
  margin-bottom: 50px !important;
}

.u-mb55 {
  margin-bottom: 55px !important;
}

.u-mb60 {
  margin-bottom: 60px !important;
}

.u-mb65 {
  margin-bottom: 65px !important;
}

.u-mb70 {
  margin-bottom: 70px !important;
}

.u-mb75 {
  margin-bottom: 75px !important;
}

.u-mb80 {
  margin-bottom: 80px !important;
}

.u-mb90 {
  margin-bottom: 90px !important;
}

.u-mb100 {
  margin-bottom: 100px !important;
}

.u-mb110 {
  margin-bottom: 110px !important;
}

.u-mb120 {
  margin-bottom: 120px !important;
}

.u-mb130 {
  margin-bottom: 130px !important;
}

.u-mb140 {
  margin-bottom: 140px !important;
}

.u-mb150 {
  margin-bottom: 150px !important;
}
@include bp(sp) {
  // margin-bottom-sp
  .u-mb0_sp {
    margin-bottom: 0 !important;
  }

  .u-mb5_sp {
    margin-bottom: 5px !important;
  }

  .u-mb10_sp {
    margin-bottom: 10px !important;
  }

  .u-mb12_sp {
    margin-bottom: 12px !important;
  }

  .u-mb15_sp {
    margin-bottom: 15px !important;
  }

  .u-mb20_sp {
    margin-bottom: 20px !important;
  }

  .u-mb25_sp {
    margin-bottom: 25px !important;
  }

  .u-mb30_sp {
    margin-bottom: 30px !important;
  }

  .u-mb35 {
    margin-bottom: 35px !important;
  }

  .u-mb40_sp {
    margin-bottom: 40px !important;
  }

  .u-mb45_sp {
    margin-bottom: 45px !important;
  }

  .u-mb50_sp {
    margin-bottom: 50px !important;
  }

  .u-mb55_sp {
    margin-bottom: 55px !important;
  }

  .u-mb60_sp {
    margin-bottom: 60px !important;
  }

  .u-mb65_sp {
    margin-bottom: 65px !important;
  }

  .u-mb70_sp {
    margin-bottom: 70px !important;
  }

  .u-mb75_sp {
    margin-bottom: 75px !important;
  }

  .u-mb80_sp {
    margin-bottom: 80px !important;
  }

  .u-mb90_sp {
    margin-bottom: 90px !important;
  }

  .u-mb100_sp {
    margin-bottom: 100px !important;
  }

  .u-mb110_sp {
    margin-bottom: 110px !important;
  }

  .u-mb120_sp {
    margin-bottom: 120px !important;
  }

  .u-mb130_sp {
    margin-bottom: 130px !important;
  }

  .u-mb140_sp {
    margin-bottom: 140px !important;
  }

  .u-mb150_sp {
    margin-bottom: 150px !important;
  }
}

// margin-right
.u-mr0 {
  margin-right: 0 !important;
}
.u-mr5 {
  margin-right: 5px !important;
}

.u-mr10 {
  margin-right: 10px !important;
}

.u-mr12 {
  margin-right: 12px !important;
}

.u-mr15 {
  margin-right: 15px !important;
}

.u-mr20 {
  margin-right: 20px !important;
}

.u-mr25 {
  margin-right: 25px !important;
}

.u-mr30 {
  margin-right: 30px !important;
}

.u-mr35 {
  margin-right: 35px !important;
}

.u-mr40 {
  margin-right: 40px !important;
}

.u-mr45 {
  margin-right: 45px !important;
}

.u-mr50 {
  margin-right: 50px !important;
}

.u-mr55 {
  margin-right: 55px !important;
}

.u-mr60 {
  margin-right: 60px !important;
}

.u-mr65 {
  margin-right: 65px !important;
}

.u-mr70 {
  margin-right: 70px !important;
}

.u-mr75 {
  margin-right: 75px !important;
}

.u-mr80 {
  margin-right: 80px !important;
}

.u-mr85 {
  margin-right: 85px !important;
}

.u-mr90 {
  margin-right: 90px !important;
}

.u-mr100 {
  margin-right: 100px !important;
}

// margin-left
.u-ml0 {
  margin-left: 0 !important;
}
.u-ml5 {
  margin-left: 5px !important;
}

.u-ml10 {
  margin-left: 10px !important;
}

.u-ml12 {
  margin-left: 12px !important;
}

.u-ml15 {
  margin-left: 15px !important;
}

.u-ml20 {
  margin-left: 20px !important;
}

.u-ml25 {
  margin-left: 25px !important;
}

.u-ml30 {
  margin-left: 30px !important;
}

.u-ml35 {
  margin-left: 35px !important;
}

.u-ml40 {
  margin-left: 40px !important;
}

.u-ml45 {
  margin-left: 45px !important;
}

.u-ml50 {
  margin-left: 50px !important;
}

.u-ml55 {
  margin-left: 55px !important;
}

.u-ml60 {
  margin-left: 60px !important;
}

.u-ml65 {
  margin-left: 65px !important;
}

.u-ml70 {
  margin-left: 70px !important;
}

.u-ml75 {
  margin-left: 75px !important;
}

.u-ml80 {
  margin-left: 80px !important;
}

.u-ml85 {
  margin-left: 85px !important;
}

.u-ml90 {
  margin-left: 90px !important;
}

.u-ml100 {
  margin-left: 100px !important;
}

// opacity,transition
.opacity {
  &:hover {
    opacity: 0.8;
  }
}
.transition {
  transition: all 0.3s ease-in-out;
}

// flex_order
.order1 {
  order: 1;
}
.order2 {
  order: 2;
}
.order3 {
  order: 3;
}
.order4 {
  order: 4;
}
@include bp(pc) {
  .order1_pc {
    order: 1;
  }
  .order2_pc {
    order: 2;
  }
  .order3_pc {
    order: 3;
  }
  .order4_pc {
    order: 4;
  }
}
@include bp(sp) {
  .order1_sp {
    order: 1;
  }
  .order2_sp {
    order: 2;
  }
  .order3_sp {
    order: 3;
  }
  .order4_sp {
    order: 4;
  }
}

// a.link
a {
  &.link_underLine {
    text-decoration: underline;
  }
  &:hover {
    cursor: pointer;
  }
}

// position
.u-pos-rerative {
  position: relative;
}
.u-pos-absolute {
  position: absolute;
}

.indent-1 {
  padding-left: 1em;
  text-indent: -1em;
}
