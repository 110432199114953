/* ------------------------------------------------------------------
 Mixin
------------------------------------------------------------------ */
// font
@mixin shuei {
  font-family: dnp-shuei-mgothic-std, sans-serif;
  font-weight: 600;
}
@mixin kinuta-marumaru {
  font-family: maru-maru-gothic-bsr-stdn, sans-serif !important;
}
@mixin gothic {
  font-family: "游ゴシック体", "YuGothic", "游ゴシック", "Yu Gothic",
    "Hiragino Gothic ProN", "ヒラギノ角ゴ Pro W3", sans-serif;
  font-weight: 400;
  @media all and (-ms-high-contrast: none) {
    font-family: "游ゴシック体", "YuGothic", "游ゴシック", "Yu Gothic",
      "Hiragino Gothic ProN", "ヒラギノ角ゴ Pro W3", sans-serif;
  }
}
// box-shadow
@mixin shadow {
  box-shadow: 0 0 18px 0 rgba(#080808, 0.54);
}
// position center
@mixin pCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@mixin pYCenter {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
@mixin pXCenter {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
// flexbox center
@mixin fCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
// bg center
@mixin bgCenter {
  background-repeat: no-repeat;
  background-position: center;
}
// anchor hover
@mixin a-hover {
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 0.7;
  }
}
// text ellipsis
@mixin ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
// text lineclamp
@mixin lineclamp($num) {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: $num;
  overflow: hidden;
}
// fonttelo icon font
@mixin fontello($content) {
  font-family: "fontello";
  content: $content;
}
// Medie Query
@mixin bp($point) {
  @if $point == sp {
    // 839px以下
    @media (max-width: 839px) {
      @content;
    }
  }

  @if $point == small {
    // 360px以下
    @media (max-width: 360px) {
      @content;
    }
  }
  @if $point == tab {
    // tabレットサイズの時
    @media (min-width: 840px) and (max-width: 1024px) {
      @content;
    }
  }

  @if $point == pc {
    // 840px以上のみ（ pcのみ ）
    @media (min-width: 840px) {
      @content;
    }
  }
  @if $point == wide {
    // 1480px以上のみ（ wideのみ ）
    @media (min-width: 1480px) {
      @content;
    }
  }
}
// vw計算
$pc-w: 1400; //pcのデザインカンプの幅を入力
$sp-w: 750; //spのデザインカンプの幅を入力
@mixin vw($W, $property, $size) {
  @if $W == pc {
    $winW: $pc-w;
    @if $property == fz {
      font-size: ($size / $winW) * 100 + vw;
    }
    @if $property == w {
      width: ($size / $winW) * 100 + vw;
    }
    @if $property == h {
      height: ($size / $winW) * 100 + vw;
    }
    @if $property == minW {
      min-width: ($size / $winW) * 100 + vw;
    }
    @if $property == minH {
      min-height: ($size / $winW) * 100 + vw;
    }
    @if $property == maxW {
      max-width: ($size / $winW) * 100 + vw;
    }
    @if $property == maxH {
      max-height: ($size / $winW) * 100 + vw;
    }
  }
  @if $W == sp {
    $winW: $sp-w;
    @if $property == fz {
      font-size: ($size / $winW) * 100 + vw;
    }
    @if $property == w {
      width: ($size / $winW) * 100 + vw;
    }
    @if $property == h {
      height: ($size / $winW) * 100 + vw;
    }
    @if $property == minW {
      min-width: ($size / $winW) * 100 + vw;
    }
    @if $property == minH {
      min-height: ($size / $winW) * 100 + vw;
    }
    @if $property == maxW {
      max-width: ($size / $winW) * 100 + vw;
    }
    @if $property == maxH {
      max-height: ($size / $winW) * 100 + vw;
    }
  }
}
// browser
@mixin browser($type) {
  @if $type == ie {
    // Internet Explorer
    @media all and (-ms-high-contrast: none) {
      @content;
    }
  }
  @if $type == ff {
    // Firefox
    @-moz-document url-prefix() {
      @content;
    }
  }
  @if $type == ch {
    // google Chrome
    @media screen and (-webkit-min-device-pixel-ratio: 0) {
      @content;
    }
  }
  @if $type == sa {
    // Safari
    @media screen and (-webkit-min-device-pixel-ratio: 0) {
      :root body,
      _::-webkit-full-page-media,
      _:future {
        @content;
      }
    }
  }
}

// =
// ========================================
// breakpoints
// ========================================

@mixin mq($breakpoint: medium, $rule: min, $subtraction: false) {
  $breakpoint: map_get($mq-breakpoints, $breakpoint);
  @if $rule == max and $subtraction {
    $breakpoint: $breakpoint - 1;
  }
  @media screen and (#{$rule}-width: $breakpoint) {
    @content;
  }
}

//
// ========================================
// ウィンドウ幅に応じて値が可変
// 参考：http://coliss.com/articles/build-websites/operation/css/font-size-used-responsive-scales.html
// ========================================

@mixin fluid-type($properties, $min-bp, $max-bp, $min-value, $max-value) {
  $min-vw: map_get($mq-breakpoints, $min-bp);
  $max-vw: map_get($mq-breakpoints, $max-bp);

  @each $property in $properties {
    #{$property}: $max-value;
  }

  @include mq(#{$max-bp}, max) {
    @each $property in $properties {
      #{$property}: calc(
        #{$min-value} +
          #{strip-unit($max-value - $min-value)} *
          (100vw - #{$min-vw}) /
          #{strip-unit($max-vw - $min-vw)}
      );
    }
  }

  @include mq(#{$max-bp}, min) {
    @each $property in $properties {
      #{$property}: $max-value;
    }
  }
}

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}
