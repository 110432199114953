/* ------------------------------------------------------------------
 PDFリンク
------------------------------------------------------------------ */
.c-pdf {
  position: relative;
  padding-left: 33px;
  color: $black;

  &::after {
    position: absolute;
    top: 55%;
    left: 0;
    display: inline-block;
    padding: 0 3px 0 3px;
    font-size: 1rem;
    font-weight: 800;
    color: #fff;
    content: "PDF";
    background-color: #d65200;
    border-radius: 2px;
    transform: translateY(-50%);
    @include gothic;
  }
}
