/* ------------------------------------------------------------------
 SHARE-SNS
------------------------------------------------------------------ */
.share {
  padding-top: 4rem;
  @include bp(sp) {
    padding-top: 3.5rem;
  }

  &Area {
    padding-bottom: 0;
    @include bp(sp) {
      margin-top: 4.5rem;
    }
  }
  &__wrap {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__title {
    margin-bottom: 2.8rem;
    font-size: 1.8rem;
    text-align: center;
    @include shuei;
    @include bp(sp) {
      margin-bottom: 2.5rem;
    }
  }

  &__item {
    margin-right: 2rem;
    margin-left: 2rem;
    font-size: 3.8rem;
    line-height: 1;
    color: #989898;
    @include bp(sp) {
      margin-right: 2.5rem;
      margin-left: 2.5rem;
    }
    &::before {
      display: inline-block;
      font-size: 3.8rem;
      line-height: 1;
      color: #989898;
    }

    .line {
      &:hover {
        color: #00b900;
      }
    }
    .facebook {
      &::before {
        @include fontello("\e803");
      }
      &:hover {
        color: #1877f2;
      }
    }
    .twitter {
      &::before {
        @include fontello("\f099");
      }
      &:hover {
        color: #1da1f2;
      }
    }
  }
}
