/* ------------------------------------------------------------------
 SNS
------------------------------------------------------------------ */
.c-sns {
  &__link {
    display: block;
    &::before {
      display: inline-block;
      font-size: 2rem;
      line-height: 1;
      color: $white;
      transition: 0.2s ease-in-out;
    }
    @include bp(pc) {
      &:hover::before {
        color: $sub-color;
      }
    }
  }

  &__insta {
    &::before {
      @include fontello("\f16d");

      font-size: 2.35rem;
    }
  }

  &__fb {
    &::before {
      @include fontello("\e803");
    }
  }

  &__tw {
    &::before {
      @include fontello("\f099");

      font-size: 2.2rem;
    }
  }
}
