/* ------------------------------------------------------------------
 下層ページ見出し
------------------------------------------------------------------ */

/* ----------------
 c-pageheader
 ---------------- */
.c-pageheader {
  margin: 0 auto;
  font-size: 3rem;
  @include shuei;
  @include bp(pc) {
    padding-top: 12rem;
    padding-bottom: 3.5rem;
  }

  @include bp(sp) {
    max-width: 100%;
    padding: 0 $spGutter;
    // padding-top: 3rem;
    padding-bottom: 0;
    margin-top: 3rem;
  }

  &__type1 {
    color: $main-color;
    text-align: center;
    @include bp(pc) {
      width: $vw-width-m;
      max-width: $width-m;
    }
  }
  &__type2 {
    text-align: left;
    @include bp(pc) {
      width: $vw-width-m;
      max-width: $width-m;
    }
  } //__type2

  &__typeWysiwyg {
    @include bp(pc) {
      width: $vw-width-wysiwyg;
      max-width: $width-wysiwyg;
    }
  } //__typeWysiwyg
  &__title {
    font-weight: 600;
  }
} //c-pageheader
