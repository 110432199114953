/* ------------------------------------------------------------------
 accordion
------------------------------------------------------------------ */

/* ----------------
 c-toggle
 ---------------- */
.c-toggle {
  &__trigger {
    position: relative;
    cursor: pointer;
    &::after {
      @include fontello("\e804");

      position: absolute;
      top: 3px;
      right: 0;
      display: inline-block;
      font-size: 2rem;
      color: $main-color;
    }
  }

  &__child {
    display: none;
  }
}
