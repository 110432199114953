/* ------------------------------------------------------------------
 六角形
------------------------------------------------------------------ */

/* ----------------
 hexagon
 ---------------- */
.c-hexagon {
  position: relative;
  overflow: hidden;
  &::before {
    display: block;
    padding-top: 115.217%;
    content: "";
  }

  &__inner1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transform: rotate(60deg);
  }

  &__inner2 {
    width: 100%;
    height: 100%;
    overflow: hidden;
    transform: rotate(60deg);
  }

  &__inner3 {
    width: 100%;
    height: 100%;
    transform: rotate(-120deg);
  }
}
