/* ------------------------------------------------------------------
 recommend
------------------------------------------------------------------ */

.borderGray {
  border-top: 1px solid $gray;
}
.recommend {
  @include bp(pc) {
    padding-top: 9rem;
  }

  @include bp(sp) {
    padding-top: 5rem;
    margin-top: 4.5rem;
  }

  .l-section__inner--wysiwyg-wide {
    @include bp(sp) {
      padding-right: 0;
      padding-left: 0;
    } //.l-section__inner
  }
  &__title {
    margin-bottom: 3rem;
    font-weight: bold;
    @include bp(pc) {
      font-size: 2.4rem;
    }
    @include bp(sp) {
      font-size: 2rem;
    }
  }

  &__item {
    @include bp(pc) {
      margin-bottom: 6rem;
    }
    @include bp(sp) {
      padding-bottom: 1.5rem;
      margin-bottom: 3.5rem;
      border-bottom: 1px solid $gray;
    }

    a {
      display: block;
    }
    &Info {
      &Date {
        display: block;
        margin-bottom: 2rem;
        font-size: 1.4rem;
        // font-weight: bold;
        color: $archive-gray;
        @include bp(sp) {
          margin-bottom: 0.5rem;
        }
      }
      &Title {
        display: block;
        font-size: 1.6rem;
        font-weight: bold;
        @include bp(pc) {
          margin-bottom: 3.5rem;
        }
        @include bp(sp) {
          margin-bottom: 1.5rem;
        }
      }
      &Img {
        &Wrap {
          @include bp(pc) {
            display: flex;
          }
        } //recommend__itemInfoImgWrap
      } //recommend__itemInfoImg

      &HashWrap {
        display: flex;
        flex-direction: column;
      }
    } //recommend__itemInfo
    &Img {
      @include bp(pc) {
        max-width: 270px;
        margin-right: 4rem;
      }
      @include bp(sp) {
        margin-bottom: 1rem;
      }
      img {
        width: 100%;
      }
    } //recommend__itemInfoImg

    &Hash {
      margin-top: auto;
      &__item {
        color: $archive-gray;
      } //recommend__itemHash
    } //__itemHash
  } //recommend__item
} //recommend
