.p-contact {
  .l-section__inner--small.adjust {
    padding-right: 0;
    padding-left: 0;
  }
  &__main {
    width: 100%;
    background-color: $bg-color-yellow;
    @include bp(pc) {
      max-width: 796px;
      padding: 5rem 5rem;
      margin-right: auto;
      margin-left: auto;
      border-radius: 35px;
    }
    @include bp(sp) {
      padding-top: 4rem;
      padding-right: $spGutter;
      padding-bottom: 4rem;
      padding-left: $spGutter;
    }

    &__title {
      margin-bottom: 2rem;
      font-size: 2.2rem;
      text-align: center;
      @include shuei;
    }
    &__txtImgWrap {
      @include bp(pc) {
        display: flex;
      }
    }
    &__txt {
      @include bp(pc) {
        padding-right: 6rem;
        margin-bottom: 5rem;
      }
      @include bp(sp) {
        margin-bottom: 1rem;
      }
    }

    &__img {
      @include bp(sp) {
        margin-bottom: 0;
        text-align: center;
      }

      img {
        max-width: 158px;
      }
    }
  } //contact__main
  &__btn {
    @include bp(pc) {
      margin-top: -4rem;
    }
  } //contact__btn
} //contact

.privateCompany {
  @include bp(pc) {
    display: flex;
  }

  .company {
    &__name {
      font-size: 2.2rem;
      @include shuei;
    }
    &__info {
      @include bp(pc) {
        width: 55%;
        margin-right: 5rem;
      }
    } //company__info
    &__map__btn a {
      padding: 0.7rem 2rem;
      font-size: 1.1rem;
      color: $main-color;
      letter-spacing: 0.01em;
      letter-spacing: 0.05em;
      background-color: $white;
      border: 1px solid $main-color;
      border-radius: 30px;
      @include shuei;
    } //company__map__btn
    &__contact {
      @include bp(pc) {
        margin-top: 3rem;
        margin-bottom: 3rem;
      }
      @include bp(sp) {
        margin-top: 3rem;
        margin-bottom: 1rem;
      }

      span {
        display: block;
        line-height: 1.5;
      }
    }
    &__map {
      @include bp(pc) {
        width: 45%;
      }
      @include bp(sp) {
        margin-bottom: 2rem;
      }
    }
  } //company
} //privateCompany
