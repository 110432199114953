.p-shoplist {
  &__title {
    display: flex;
    margin-bottom: 2.5rem;
    font-size: 2.2rem;
    @include shuei;
    @include bp(sp) {
      margin-bottom: 1rem;
    }

    &__motif {
      margin-right: 1rem;
    }
    &__imgWrap {
    }
    &__sub {
      margin-bottom: 2rem;
      font-size: 1.8rem;
      @include shuei;
      @include bp(sp) {
        margin-bottom: 1.5rem;
      }
    }
  } //shoplist__title
  &__img {
    width: 100%;
    @include bp(pc) {
      max-width: 350px;
    }
    &.displaySp {
      max-width: 184px;
      margin-right: auto;
      margin-left: auto;
    }
  } //.p-shoplist__img
  &__txt {
    &Wrap {
      @include bp(pc) {
        flex: 1;
        padding-right: 10rem;
      }
    }
    &__imgWrap {
      display: flex;
      @include bp(sp) {
        flex-wrap: wrap;
      }
    }
  } //.p-shoplist__txt
  &__onlineBtn {
    margin-top: 3rem;
    @include bp(sp) {
      text-align: center;
    }
  }

  // swiper
  &__txt__swiperWrap {
    display: flex;
    @include bp(sp) {
      flex-wrap: wrap;
    }
  }
  &__swiperWrap {
    width: 100%;
    overflow: hidden;
    @include bp(pc) {
      max-width: 350px;
    }
  }
  .swiper-slide {
    width: 100% !important;
    @include bp(pc) {
      max-width: 350px;
    }
    img {
      width: 100%;
    }
    .caption {
      margin-top: 0.5rem;
      line-height: 1.2;
    }
  }
  .swiper-pagination-bullets {
    display: none;
  }

  &__map {
    &__col {
      &__wrap {
        display: flex;
        @include bp(sp) {
          flex-wrap: wrap;
        }
      }
      &__side {
        position: relative;
        width: 100%;
        overflow: hidden;
        @include bp(pc) {
          max-width: 65px;
        }
        &::after {
          position: absolute;
          z-index: 0;
          content: "";
          background-color: #c6c6c6;
          @include bp(pc) {
            left: 1rem;
            width: 1px;
            height: 100%;
          }
          @include bp(sp) {
            top: 50%;
            right: -6rem;
            width: 100%;
            height: 1px;
          }
        }
      } //__side
      &__main {
        @include bp(pc) {
          flex: 1;
        }
      } //__side
    } //__col
    &__prefectures {
      &__item {
        position: relative;
      }
      &__title {
        position: relative;
        font-size: 2rem;
        letter-spacing: 0.5em;
        @include shuei;
        @include bp(pc) {
          left: -1rem;
          z-index: 1;
          display: inline-block;
          padding-bottom: 1rem;
          text-align: start;
          background-color: #fff;
          writing-mode: vertical-rl;
        }
      }
    } //__prefectures
    &__shop {
      &__map {
        iframe {
          @include bp(sp) {
            height: 300px;
          }
        }

        &Btn {
          span {
            font-size: 1.1rem;
            line-height: 0.5;
          }
        }
      }
      &__item {
        .shop {
          &__name {
            margin-bottom: 0.5rem;
            font-weight: bold;
          } //&__name
          &__businessHours,
          &__address {
            font-size: 1.4rem;
          } //&__businessHours,&__address
        } //shop
      } //__shop__item
    } //__shop
  } //map

  .indent-1 {
    padding-left: 1.4em;
    text-indent: -1.4em;
  }
} //shoplist
