/* ----------------
 波の装飾
 ---------------- */
.cloud-wrap {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  margin-top: 10px;
  margin-left: 10px;
}

.cloud-wrap::before,
.cloud-wrap::after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-block;
  width: 100%;
  height: 100%;
  content: "";
  background-color: currentColor;
  border-radius: 15px;
}
.cloud-wrap::before {
  transform: translate(-50%, -50%) rotate(-69deg);
}

.cloud-wrap::after {
  transform: translate(-50%, -50%) rotate(-136deg);
}

.cloud-wrap .cloud {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: currentColor;
  border-radius: 15px;
  transform: rotate(0);
}

.cloud-wrap .cloud::before,
.cloud-wrap .cloud::after {
  position: absolute;
  display: inline-block;
  width: 100%;
  height: 100%;
  content: "";
  background-color: inherit;
  border-radius: 15px;
}

.cloud-wrap .cloud::before {
  transform: rotate(-21deg);
}

.cloud-wrap .cloud::after {
  transform: rotate(45deg);
}

//   /* テキスト */
//   .cloud-wrap .text{
// 	position: absolute;
// 	top: 50%;
// 	left: 50%;

// 	margin: 0;
// 	font-size: 1.4em;
// 	color: white;
// 	/* 単一行 */
// 	white-space: nowrap;

// 	transform: translate(-50%,-50%);
// 	z-index: 2;
//   }

/* 雲の色 */
.bgWhite {
  background-color: $white;
}

.olivedrab {
  color: olivedrab;
}
