/* ------------------------------------------------------------------
 is-anim-zoomIn
------------------------------------------------------------------ */
.is-scroll-play-show01,
.is-scroll-play-show02,
.is-scroll-play-show03 {
  opacity: 0;
}
.is-anim-zoomIn {
  animation: zoomIn 0.8s cubic-bezier(0.25, 1, 0.5, 1) 1 forwards;
}

@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.is-hide {
  opacity: 0;
}

/* ------------------------------------------------------------------
 is-anim-slideshow____mv
------------------------------------------------------------------ */
.is-anim-slideshow {
  animation: is-anim-slideshow 500s linear infinite;
}

@include bp(pc) {
  @keyframes is-anim-slideshow {
    0% {
      transform: translate3d(0, 0, 0);
    }
    100% {
      transform: translate3d(-5724px, 0, 0);
    }
  }
}
@include bp(sp) {
  @keyframes is-anim-slideshow {
    0% {
      transform: translate3d(0, 0, 0);
    }
    100% {
      transform: translate3d(-5724px, 0, 0);
    }
  }
}

/* ------------------------------------------------------------------
 is-anim-slideMove---gion
------------------------------------------------------------------ */
.is-anim-slideMove img {
  max-width: 228px;
}
.is-anim-slideMove {
  width: 100vw;
  height: 90px;
  animation: slideMove 70s linear infinite; /* 秒数等はお好きな数値に、infiniteは残す */
}
@keyframes slideMove {
  0% {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-$move_gion-anim-w);
  }
}

/* ------------------------------------------------------------------
 is-anim-poyopoyo
------------------------------------------------------------------ */

@mixin is-anim-poyopoyo {
  opacity: 1;
  animation: poyopoyo 2s ease-out infinite;
}
@keyframes poyopoyo {
  0%,
  40%,
  60%,
  80% {
    transform: scale(1);
  }
  50%,
  70% {
    transform: scale(0.95);
  }
}
